export enum PostTypes {
	Post = 'Post',
	Poll = 'Poll',
	Quiz = 'Quiz',
	Paid = 'Paid'
}

export enum PostAttachmentsTypes {
	Image = 'Image',
	Video = 'Video',
	Voice = 'Voice',
	Document = 'Document',
	Audio = 'Audio',
	VideoNote = 'VideoNote',
	Animation = 'Animation',
	MessagePreview = 'MessagePreview'
}
