





























import { PayedPostMessage } from '@/includes/types/Post.types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Product } from 'piramis-base-components/src/components/Shop/Products/types'
import store from '@/store/store'

@Component({})
export default class PaidPostPreview extends Vue {
  @Prop() message!: PayedPostMessage

  @Prop() boardKey!:string

  get product(): Product | null {
    return store.state.shopState.products?.find(p => p.id === this.message.product_id) ?? null
  }

  get imageLink():string {
    return `https://api.presscode.app/cabinet/v1/tg/GetProductImage?target=${ this.boardKey.split('.')[0] }&amp;file_descriptor=${ this.product?.image }`
  }
}
