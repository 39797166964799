import BaseApi from "@/includes/services/BaseApi";

export class PropertiesService {
	static createProperty(type: string, body: { board_key: string, name: string, type: string }): Promise<{ index: number }> {
		return BaseApi.sendRequest(type, 'CreateProperty', body)
	}

	static deleteProperty(type: string, body: { board_key: string, index: number }): Promise<any> {
		return BaseApi.sendRequest(type, 'DeleteProperty', body)
	}
}
