import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { RootState } from "@/store/state";
import { FormService } from '@/includes/services/FormService'
import { Form } from '@/includes/types/Form.types'

import { Commit } from 'vuex'

export default {
    getForms({ commit, state }: { commit: Commit, state: RootState }): Promise<Array<Required<Form>> | void> | undefined  {
        if (state.boardsState.activeBoard) {
            return FormService.getForms('tg', { board_key: state.boardsState.activeBoard.board })
                .then((data) => {
                    commit('SET_FORMS_TO_STORE', data.items)

                    return data.items
                })
                .catch(errorNotification)
        }
   },
    deleteForm({ commit, state }: { commit: Commit, state: RootState }, key: string): Promise<void> {
        return FormService.deleteForm('tg', { board_key: state.boardsState.activeBoard!.board, key })
            .then(({ form }) => {
                successNotification()
                commit('REMOVE_FORM', form.key)
            })
            .catch(errorNotification)
    },
    updateForm({ commit, state }: { commit: Commit, state: RootState }, form:Required<Form>): Promise<Required<Form> | void> {
        return FormService.updateForm('tg', {
            board_key: state.boardsState.activeBoard!.board,
            ...form
        })
            .then((res) => {
                successNotification()

                commit('UPDATE_FORM_BY_KEY', res.form)
                return res.form
            })
            .catch(errorNotification)
    },
    createForm({ commit, state }: { commit: Commit, state: RootState }, form:Required<Form>): Promise<Required<Form> | void> {
        return FormService.createForm('tg', {
            board_key: state.boardsState.activeBoard!.board,
            ...form
        })
            .then((res) => {
                successNotification()

                commit('ADD_FORM', res.form)
                return res.form
            })
            .catch(errorNotification)
    },
}
