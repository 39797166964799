import BaseApi from "@/includes/services/BaseApi";

export class LabelsService {
	static createLabel(type: string, body: { board_key: string, title: string }): Promise<{ index: number }> {
		return BaseApi.sendRequest(type, 'CreateLabel', body)
	}

	static deleteLabel(type: string, body: { board_key: string, index: number }): Promise<any> {
		return BaseApi.sendRequest(type, 'DeleteLabel', body)
	}
}
