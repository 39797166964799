























import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { ButtonConfig } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types";

@Component
export default class TelegramPostButtons extends Vue {
  @Prop() buttons!: Array<ButtonConfig>
}
