import { RootState } from '@/store/state'
import { PropertyFieldType } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import i18n from '@/i18n/i18n'
import { snakeCase } from 'lodash'

export default {
	getPropertiesOptions: (state: RootState): Array<{ label: string, value: number, type: PropertyFieldType, icon?: { name: string, iconPack: string, color: string } }> => {
		const properties: Array<{ label: string, value: number, type: PropertyFieldType }> = []

		const getPropertyIcon = (type: PropertyFieldType): string => {
			switch (type) {
				case PropertyFieldType.Date:
					return 'event'
				case PropertyFieldType.Double:
					return 'pin'
				case PropertyFieldType.Number:
					return 'pin'
				case PropertyFieldType.Email:
					return 'email'
				case PropertyFieldType.Phone:
					return 'phone_iphone'
				case PropertyFieldType.Time:
					return 'schedule'
				case PropertyFieldType.DateTime:
					return 'pending_actions'
				case PropertyFieldType.String:
					return 'text_fields'
				case PropertyFieldType.Flag:
					return 'flag'
				default:
					return ''
			}
		}

		properties.push(...state.boardsState.activeBoard!.config.properties.map((property) => {
			return {
				label: property.name,
				value: property.id,
				type: property.type,
				icon: {
					name: getPropertyIcon(property.type),
					iconPack: 'material-icons',
					color: 'primary'
				}
			}
		}))

		properties.push(...state.AdminTemplate.runtime_config!.system_properties.map((property) => {
			return {
				label: i18n.t(`${ snakeCase(property.name) }_option`).toString(),
				value: property.id,
				type: property.type,
				icon: {
					name: property.name === 'DISCOUNT' ? 'local_offer' : getPropertyIcon(property.type),
					iconPack: 'material-icons',
					color: 'primary'
				}
			}
		}))

		return properties
	},

	properties(state: RootState): any {
		return [
			...state.boardsState.activeBoard!.config.properties.map((prop) => Object.assign(prop, { group: 'custom' })),
			...state.AdminTemplate.runtime_config!.system_properties.map((prop) => Object.assign(prop, { group: 'system' })),
		]
	},

	labels(state: RootState): any {
		return state.boardsState.activeBoard!.config.labels
	},
}
