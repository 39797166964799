import { Poll, PostMessage, Quiz } from '@/includes/types/Post.types'

export type DraftsState = {
    drafts: null | Array<{ key: Record<string, any>, message: PostMessage | Poll | Quiz }>
    loading: boolean
}

export const draftsState: DraftsState = {
    drafts: null,
    loading: false
}
