


































































import { PostDataStat } from "@/includes/types/Post.types";
import i18n from "@/i18n/i18n";

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import Vue from 'vue'
import { Component, Prop, VModel } from "vue-property-decorator";

@Component({
  data() {
    return {
      i18n
    }
  }
})
export default class PostInfo extends Vue {
  @VModel() postInfoModal!: boolean

  @Prop() statistics!: Array<PostDataStat>

  @Prop() targetChannels!: Array<SelectOptionData>

  goToComments(link: string): string {
    return location.href = link
  }

  getStatChannelAvatar(stat: PostDataStat): string {
    return this.targetChannels.find((target) => target.value === stat.channel_id || target.value === stat.chat_id)!.image!.src
  }
}
