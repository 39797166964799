import BaseApi from '@/includes/services/BaseApi'
import { InviteLink, ListInviteLinkModel } from '@/components/InviteLinks/types'

export class InviteLinksService {

    static getInviteLinks(type: string, body: { board_key: string, target_id: number }): Promise<{ links: Array<ListInviteLinkModel> }> {
        return BaseApi.sendRequest(type, 'getinvitelinks', body)
    }

    static createInviteLink(type: string, body: { board_key: string, target_id: number } & InviteLink): Promise<ListInviteLinkModel> {
        //TODO, Переделать! Сейчас все приходит в корне, будет обернуто в поле link
        return BaseApi.sendRequest(type, 'createinvitelink', body)
    }

    static setInviteLink(type: string, body: { board_key: string, link_key: string } & InviteLink): Promise<ListInviteLinkModel> {
        //TODO, Переделать! Сейчас все приходит в корне, будет обернуто в поле link
        return BaseApi.sendRequest(type, 'setinvitelink', body)
    }

    static deleteInviteLink(type: string, body: { board_key:string, link_key:string }): Promise<void> {
        return BaseApi.sendRequest(type, 'deleteinvitelink', body)
    }

    static getInviteLinksStat(type: string, body: { board_key: string, target_id: number, interval: InviteLinksStatInterval }) {
        return BaseApi.sendRequest(type, 'getinvitelinksstat', body)
    }

}

type InviteLinksStatInterval = ShortInterval | FullInterval

type ShortInterval = { interval_type: 'Short' }

type FullInterval = {
    interval_type: 'Full'
    from: string
    to: string
}
