import { RootState } from '@/store/state'
import i18n from '@/i18n/i18n'

export default {
    channelLicenseType: (state:RootState) => {
        const activeChannel = state.channelsState.activeChannel
        const LICENSE_TYPES = {
            '0': '',
            '1': 'Business'
        }

        if (activeChannel) {
            return LICENSE_TYPES[activeChannel.license_type]
        }
    },
    hasChannelGroup: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        return activeChannel && activeChannel.linked_chat_id !== null
    },
    channelLicenseBuyButtonTitle: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            if (activeChannel.free_licence && !activeChannel.licence_expired) {
                return i18n.t('button_license_prolongate').toString()
            } else if (activeChannel.free_licence && activeChannel.members_count < 1000) {
                return i18n.t('license_buy').toString()
            } else if ((activeChannel.free_licence && activeChannel.members_count > 1000) || !activeChannel.licence_expired) {
                return i18n.t('button_license_prolongate').toString()
            } else {
                return i18n.t('license_buy').toString()
            }

        }
    },
    freeLicenseTypeTitle: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            if (activeChannel.free_licence) {
                if (!activeChannel.licence_expired) {
                    return ''
                }

                if (activeChannel.licence_expired && activeChannel.members_count < 1000) {
                    return i18n.t('free_license_under_1000').toString()
                }

                return i18n.t('free_license_test_period').toString()
            }
        }
    },
    isLicenseExpiredTitle: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            if (activeChannel.free_licence && !activeChannel.licence_expired) {
                return i18n.t('license_expires').toString()
            } else if (activeChannel.free_licence && activeChannel.members_count < 1000) {
                return i18n.t('buy_license').toString()
            } else if ((activeChannel.free_licence && activeChannel.members_count > 1000) || !activeChannel.licence_expired) {
                return i18n.t('license_expires').toString()
            } else {
                return i18n.t('buy_license').toString()
            }
        }
        },
    licenseTimeIfNotFreeLicense: (state:RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            if ((activeChannel.free_licence && activeChannel.members_count > 1000) || (activeChannel.free_licence && !activeChannel.licence_expired) || !activeChannel.licence_expired) {
               return activeChannel.license_time
            }

            return ''
        }
    }
}