import '@/assets/css/main.css'
import '@/assets/scss/main.scss'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'material-icons/iconfont/material-icons.css'
import { extend, localize } from 'vee-validate'
// @ts-ignore
import en from 'vee-validate/dist/locale/en'
// @ts-ignore
import ru from 'vee-validate/dist/locale/ru'
import { email, integer, numeric, required } from 'vee-validate/dist/rules'
import 'video.js/dist/video-js.min.css'

import { NavigationGuardNext, Route } from 'vue-router'
import Vue, { PluginFunction, VNode } from 'vue'

import { DynamicReactiveRefs, ReactiveRefs } from 'vue-reactive-refs'
import VueScreen from 'vue-screen'
import App from './App.vue'
import './globalComponents.js'
import i18n from './i18n/i18n'
import router from './router/index'
import store from './store/store'
import isMobile from 'piramis-js-utils/lib/isMobile'

// @ts-ignore
import VueAwesomeSwiper from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'

import Commerce from 'piramis-base-components/src/plugins/Commerce/index'

import RouteHelpers from 'piramis-base-components/src/plugins/RouteHelpers/index'
import AdminTemplate from 'piramis-base-components/src/plugins/AdminTemplate'
import { DynamicHeader, MenuItemType } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem'
import { AdminTemplateOptions, Layout } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/Config'
import { errorNotification } from '@/includes/services/NotificationService'
import { canCreatePost, hasChangeConfigPermission } from '@/includes/PermissionHelper'
import '@/views/PostFeed/assets/styles.css'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'piramis-base-components/src/assets/scss/main.scss'
import { Board } from '@/includes/types/Board.types'
import { BoardService } from '@/includes/services/BoardService'
import PostFeed from '@/views/PostFeed/PostFeed.vue'
import VueCustomElement from 'vue-custom-element'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import moment from 'moment'
import {
    actionTypeBreadcrumb,
    baseBoardBreadcrumbs,
    baseChannelBreadcrumbs
} from '@/includes/AppConfigBreadcrumbsHelper'
import { ProductNames } from 'piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/types'
import { Guid } from 'guid-typescript'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'

// Feather font icon
require('./assets/css/iconfont.css')

localize({
    ru,
    en,
})

localize(i18n.locale)

extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('integer', integer)
extend('url', {
    validate(value) {
        // eslint-disable-next-line
        return value.text(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)
    },
})

// @ts-ignore
extend('is_not_0', { validate: (val) => val !== 0, message: i18n.t('validation_is_not_0') })

Vue.use(Commerce as unknown as PluginFunction<any>, { store })
Vue.use(RouteHelpers as unknown as PluginFunction<any>, { router })

Vue.use(VueAwesomeSwiper)
Vue.use(VueScreen, 'tailwind')
Vue.use(DynamicReactiveRefs)
Vue.use(ReactiveRefs)

Vue.use(Antd)

const boardsDynamicHeader = (): Array<Record<'menuItem', DynamicHeader>> => {
    return store.getters.sortedBoards.map(board => {
        const boardId = board.board.split('.')[0]

        return {
            menuItem: {
                type: MenuItemType.DynamicHeader,
                isVisible: [
                    (ctx) => !ctx.route.params.id && !ctx.route.params.channelId,
                ],
                header: () => board.title,
                avatar: () => board.avatar,
                to: {
                    name: 'posts_planner',
                    params: {
                        id: boardId
                    },
                    query: {
                        ...!canCreatePost(board.permissions) ?
                            { calendar: CalendarPostsSource.Suggested } :
                            { calendar: CalendarPostsSource.Schedule }
                    }
                }
            }
        }
    })
}

const adminTemplateOptions: AdminTemplateOptions = {
    config: {
        footer: {
            version: store.state.metaModule.PACKAGE_VERSION,
            ourProducts: {
                exclude: [
                    ProductNames.PRESSCODEBOT
                ],
            }
        },
        meta: {
            projectName: 'PressCode.app',
            apiUrl: 'api.presscode.app/cabinet',
            projectDomain: 'https://presscode.app',
            sidebarLogo: require('./assets/images/presscode-logo/presscodebot_logo.svg'),
            loginPageLogo: require('./assets/images/presscode-logo/logo_title.png'),
            tgResolvers: {
                supportBotDomain: 'PressCodeSupportRu',
                promotionBotDomain: 'ChatKeeperPromotionBot',
                mainBotDomain: 'Presscodebot',
            },
            login: {
                botAuthLink: 'https://t.me/PresscodeBot?start=cabinetlink',
                loginWidget: {
                    botLogin: 'presscodebot',
                    tgWidgetAuthLink: 's/tg/d/login?bot_id=1852523856',
                }
            },
            languageCodes: [ 'ru', 'en' ],
            isSaveButtonVisible: false,
            paymentEntity: {
                target_type: (ctx) => {
                    if (store.state.boardsState.boards) {
                        const currentBoard = store.getters.getBoardById(ctx.route.params.id)

                        if (currentBoard) {
                            const channel_id = ctx.route.params.channelId

                            return {
                                target_id: {
                                    board_key: currentBoard.board,
                                    ...channel_id ? { channel_id } : {},
                                },
                                target_type: channel_id ? 'channel' : 'board'
                            }
                        }
                    }

                    throw new Error('Boards are not initialised')
                }
            },
        },
        loyaltySystem: {
            partnerBlock: {
                partnerLink: (localeCode) => 'https://t.me/ChatKeeperPromotion/?start=loyalty_parnters'
            },
            companiesBlock: {
                termsOwnerLink: (localeCode) => 'https://t.me/ChatKeeperPromotion/?start=loyalty_parnters'
            }
        },
        companyRegistration: {
            powerOfAttorney: (localeCode): string => 'https://presscode.app/wp-content/uploads/2022/03/power_of_attorney_ur.doc',
            howToRegisterUr: (localeCode): string => 'https://presscode.app/wp-content/uploads/2022/03/how_to_register_ur.docx',
        },
        search: {
            entities: (ctx): any => {
                const boards = store.state.boardsState.boards

                if (boards !== null && Array.isArray(boards) && boards.length) {
                    return boards.map((e: Board & { id?: string }) => {
                        e.id = e.board.split('.')[0]
                        return e
                    })
                } else {
                    return []
                }
            },
            path: (ctx, router, selectedId): void => {
                router.push({
                    name: 'posts_planner',
                    params: {
                        id: selectedId,
                    }
                })
            }
        },
        serviceData: {
            type: 'ServiceData',
            tariffs: {
                Business: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/business.png'),
                    color: 'rgb(177,69,250)',
                },
                Prime: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/business.png'),
                    color: 'rgb(177,69,250)',
                },
            },
        },
        topMenu: [
            {
                menuItem: {
                    type: MenuItemType.Component,
                    isVisible: [],
                    component: () => import('@/components/SuggessNotifications.vue')
                }
            },
            {
                menuItem: {
                    guid: Guid.create().toString(),
                    type: MenuItemType.DropDown,
                    name: 'help',
                    featherIcon: true,
                    icon: 'help-circle',
                    isVisible: [ true ],
                    class: [ 'text-warning' ],
                    submenu: [
                        {
                            menuItem: {
                                isVisible: [ true ],
                                type: MenuItemType.DropDownLink,
                                name: 'help_tech_support',
                                icon: 'life-buoy',
                                featherIcon: true,
                                url: () => 'https://t.me/PressCodeSupportRu'
                            },
                        },
                    ],
                },
            }
        ],
        sidebarMenu: [
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layout',
                    isVisible: [ true ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Boards',
                            path: '',
                            component: (): any => import('./views/boards.vue'),
                        },
                    },
                    class: [],
                },
            },
            boardsDynamicHeader,
            {
                menuItem: {
                    type: MenuItemType.DynamicHeader,
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                    ],
                    header: (ctx) => {
                        if (store.state.boardsState.boards && ctx.route) {
                            const currentBoard = store.state.boardsState.boards.find((b) => b.board.split('.')[0] === ctx.route.params.id)

                            if (currentBoard) {
                                return currentBoard.title
                            }

                            throw new Error('Board not found')
                        }
                        throw new Error('Boards are not initialised or context route is null')
                    },
                    avatar: (ctx) => {
                        if (store.state.boardsState.boards && ctx.route) {
                            const currentBoard = store.state.boardsState.boards.find((b) => b.board.split('.')[0] === ctx.route.params.id)

                            if (currentBoard) {
                                return currentBoard.avatar
                            }

                            throw new Error('Board not found')
                        }
                        throw new Error('Boards are not initialised or context route is null')
                    },
                    class: [ 'empty' ],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-settings',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Common_settings',
                            path: '/board/:id/settings',
                            component: (): any => import('./views/board-settings/common-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_common_settings_title') }
                                    ]
                                }
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-radio',
                    isVisible: [
                        (ctx: any): boolean => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channels',
                            path: '/board/:id/channels',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channels_title') },
                                    ]
                                },
                            },
                            component: (): any => import('./views/channels.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-message-square',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Bot_feedback',
                            path: '/board/:id/feedback',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_bot_feedback_title') }
                                    ]
                                }
                            },
                            component: (): any => import('./views/board-settings/bot-feedback.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-calendar',
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'posts_planner',
                            path: '/board/:id',
                            component: (): any => import('./views/posts-planner.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_posts_planner_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-rss',
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Distributions',
                            path: '/board/:id/distributions/',
                            component: (): any => import('./views/distributions-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_distributions_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    icon: 'icon-users',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Users_options',
                            path: '/board/:id/users',
                            component: (): any => import('./views/Users/Users.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_users_options_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        () => hasChangeConfigPermission(),
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => store.getters.hasFlows,
                    ],
                    tag: (): string => moment().isAfter(store.state.boardsState.activeBoard!.license_time) ? 'Prime' : '',
                    tagColor: () => '#f75357',
                    icon: 'icon-git-pull-request',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Flows_List',
                            path: '/board/:id/flows-list',
                            component: (): any => import('./views/Flow/FlowsList.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_flows_list_title') }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        () => hasChangeConfigPermission(),
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => {
                            const board = store.state.boardsState?.activeBoard

                            if (board) {
                                const labels = board.config.labels
                                const properties = board.config.properties

                                return !!Object.keys(labels).length || properties.length > 0
                            }

                            return false
                        },
                    ],
                    icon: 'icon-archive',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Bot_Properties',
                            path: '/board/:id/bot-properties',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_bot_properties_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/BotProperties/BotPropertiesView.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        () => hasChangeConfigPermission(),
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => store.getters.hasCashRegisters,
                    ],
                    icon: 'icon-shopping-cart',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Shop',
                            path: '/board/:id/shop',
                            component: (): any => import('./views/shop/shop.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_shop_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-folder',
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => canCreatePost(),
                        () => store.getters.hasDrafts,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Drafts_list',
                            path: '/board/:id/drafts/',
                            component: (): any => import('./views/drafts-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_drafts_list_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                        () => {
                            const board = store.state.boardsState?.activeBoard

                            if (board) {
                                const styles = board.config.styles

                                return !!styles && !!Object.keys(styles).length
                            }

                            return false
                        },
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Styles',
                            path: '/board/:id/styles',
                            component: (): any => import('./views/styles.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_styles_title') },
                                    ]
                                },
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-edit',
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => store.getters.hasForms,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Forms',
                            path: '/board/:id/forms',
                            component: (): any => import('./views/form-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_forms_title') },
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-file-text',
                    isVisible: [
                        (ctx) => !!ctx.route.params.id,
                        (ctx) => !ctx.route.params.channelId,
                        () => hasChangeConfigPermission(),
                        () => store.getters.hasArticles,
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'articles_list',
                            path: '/board/:id/articles-list',
                            meta: {
                                disableLoader: true,
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_articles_list_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/articles-list.vue')
                        }
                    },
                    class: [],
                }
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Auto_reply_actions_item',
                            path: '/board/:id/reply-actions/:actionId?/:actionType',
                            component: (): any => import('./views/board-settings/auto-reply-actions-item.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_board_personal_title') },
                                        {
                                            label: i18n.t('menu_bot_feedback_title'),
                                            routeName: 'Bot_feedback'
                                        },
                                        { label: i18n.t('menu_auto_reply_actions_item_title') },
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Pm_commands_item',
                            path: '/board/:id/commands/:commandId?/:actionType',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_board_personal_title') },
                                        {
                                            label: i18n.t('menu_bot_feedback_title'),
                                            routeName: 'Bot_feedback'
                                        },
                                        { label: i18n.t('menu_pm_commands_item_title') },
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                }
                            },
                            component: (): any => import('./views/board-settings/pm-commands-item.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    featherIcon: true,
                    icon: 'icon-shopping-bag',
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Buy_board',
                            path: '/board/:id/buy',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_board_personal_title') },
                                        { label: i18n.t('menu_buy_board_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/buy-board.vue'),
                        },
                    },
                    'class': [ 'vu-nav-license' ],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'User_profile',
                            path: '/board/:id/user-profile/:user_id',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_board_personal_title') },
                                        { label: i18n.t('menu_users_options_title'), routeName: 'Users_options' },
                                        { label: i18n.t('menu_user_profile_title') },
                                    ]
                                }
                            },
                            component: (): any => import('./views/Users/Profile.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'Flow',
                            path: '/board/:id/flow/:actionType',
                            query: {},
                            component: (): any => import('./views/Flow/Flow.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'shared',
                            path: '/shared/article/:articleKey',
                            meta: {
                                requiresAuth: false
                            },
                            component: (): any => import('./views/article-read-only.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'Flow_Screenshot',
                            path: '/flow-screenshot/:request',
                            meta: {
                                requiresAuth: false
                            },
                            component: (): any => import('./views/Flow/Flow.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-clipboard',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'article',
                            path: '/board/:id/articles-list/:actionType',
                            component: (): any => import('./views/article.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_articles_list_title'), routeName: 'articles_list' },
                                        ...ctx.route.query.articleKey ? [ { label: ctx.route.query.articleKey.toString().split('.')[0] } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Style',
                            path: '/board/:id/styles/:actionType',
                            component: (): any => import('./views/style.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_styles_title'), routeName: 'Styles' },
                                        ...ctx.route.query.styleId ? [ { label: ctx.route.query.styleId.toString().split('.')[0] } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Distribution',
                            path: '/board/:id/distributions/:distributionId?/:actionType',
                            component: (): any => import('./views/distribution-item.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_distributions_title'), routeName: 'Distributions' },
                                        ...ctx.route.params.distributionId ? [ { label: ctx.route.params.distributionId.toString().split('.')[0] } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            },
                        },
                    },
                    class: [],

                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Full_form',
                            path: '/board/:id/forms/:formKey?/:actionType',
                            component: (): any => import('./views/form-item.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_forms_title'), routeName: 'Forms' },
                                        ...ctx.route.params.formKey ? [ { label: ctx.route.params.formKey.toString().split('.')[0] } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                },
                            },
                            beforeEnter: (to, from, next) => {
                                if (!Object.values(BaseItemViewAction).includes(to.params.actionType as BaseItemViewAction)) {
                                    next({
                                        name: 'Forms', params: {
                                            id: to.params.id
                                        }
                                    })
                                }

                                next()
                            }
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-layers',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Fullscreen,
                        config: {
                            name: 'FormPublic',
                            path: '/questionnaire/:formToken',
                            component: (): any => import('./views/form-public.vue'),
                            meta: {
                                requiresAuth: false,
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.DynamicHeader,
                    to: { name: 'Channel_main_settings' },
                    isVisible: [
                        (ctx) => !!ctx.route.params.channelId,
                        () => hasChangeConfigPermission()
                    ],
                    header: (ctx) => store.state.channelsState.activeChannel ? store.state.channelsState.activeChannel.title : '',
                    avatar: (ctx) => store.state.channelsState.activeChannel ? store.state.channelsState.activeChannel.avatar : '',
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-radio',
                    isVisible: [
                        (ctx) => !!ctx?.route.params.channelId,
                        () => hasChangeConfigPermission()
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_main_settings',
                            path: '/board/:id/channel/:channelId',
                            component: (): any => import('./views/channel/channel-main-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_main_settings_title') }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-message-circle',
                    isVisible: [
                        (ctx) => !!ctx?.route.params.channelId,
                        () => store.getters.hasChannelGroup,
                        () => hasChangeConfigPermission()
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_comments',
                            path: '/board/:id/channel/:channelId/comments-settings',
                            component: (): any => import('./views/channel/comments-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_comments_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-send',
                    isVisible: [
                        (ctx) => !!ctx?.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Publish_settings',
                            path: '/board/:id/channel/:channelId/publish-settings',
                            component: (): any => import('./views/channel/publish-settings.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_publish_settings_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-shield',
                    isVisible: [
                        (ctx) => !!ctx?.route.params.channelId,
                        () => hasChangeConfigPermission()
                    ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_protection',
                            path: '/board/:id/channel/:channelId/protection',
                            component: (): any => import('./views/channel/channel-protection.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_protection_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx?.route.params.channelId,
                        () => hasChangeConfigPermission()
                    ],
                    icon: 'icon-users',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Channel_subscription',
                            path: '/board/:id/channel/:channelId/subscription',
                            component: (): any => import('./views/channel/channel-subscription.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_channel_subscription_title') }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [
                        (ctx) => !!ctx.route.params.channelId,
                        () => hasChangeConfigPermission()
                    ],
                    icon: 'icon-link',
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'invite_links_list',
                            path: '/board/:id/channel/:channelId/link-list',
                            query: {},
                            component: (): any => import('./views/invite-links/link-list.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_invite_links_title') },
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.DropDown,
                    guid: Guid.create().toString(),
                    name: 'Statistics_all',
                    isVisible: [
                        (ctx) => !!ctx?.route.params.channelId,
                        () => hasChangeConfigPermission(),
                    ],
                    featherIcon: true,
                    icon: 'icon-bar-chart',
                    submenu: [
                        {
                            menuItem: {
                                type: MenuItemType.Route,
                                featherIcon: true,
                                icon: 'icon-bar-chart-',
                                isVisible: [
                                    (ctx) => !!ctx?.route.params.channelId,
                                    () => hasChangeConfigPermission()
                                ],
                                routeConfig: {
                                    layout: Layout.Panel,
                                    config: {
                                        name: 'Channel_statistics',
                                        path: '/board/:id/channel/:channelId/statistics',
                                        component: (): any => import('./views/channel/channel-statistics.vue'),
                                        meta: {
                                            breadcrumbs: (ctx) => {
                                                return [
                                                    ...baseBoardBreadcrumbs(ctx.route),
                                                    ...baseChannelBreadcrumbs(ctx.route),
                                                    { label: i18n.t('menu_statistics_all_title') },
                                                        { label: i18n.t('menu_channel_statistics_title') },
                                                ]
                                            }
                                        },
                                    },
                                },
                                class: [],
                            },
                        },
                        {
                            menuItem: {
                                type: MenuItemType.Route,
                                isVisible: [ true ],
                                icon: 'icon-bar-chart-',
                                featherIcon: true,
                                routeConfig: {
                                    layout: Layout.Panel,
                                    config: {
                                        name: 'Invite_links_statistics',
                                        path: '/board/:id/channel/:channelId/link-statistics/:linkCode?',
                                        query: {},
                                        component: (): any => import('./views/invite-links/link-statistics.vue'),
                                        meta: {
                                            breadcrumbs: (ctx) => {
                                                return [
                                                    ...baseBoardBreadcrumbs(ctx.route),
                                                    ...baseChannelBreadcrumbs(ctx.route),
                                                    { label: i18n.t('menu_statistics_all_title') },
                                                    { label: i18n.t('menu_invite_links_statistics_title') },
                                                ]
                                            }
                                        },
                                    },
                                },
                                class: [],
                            },
                        },
                    ],
                }
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'LayersIcon',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'post',
                            path: '/board/:id/post/:actionType',
                            component: (): any => import('./views/post.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    let label = ''

                                    if (ctx?.route.params.actionType === 'new') label = i18n.t('new_post').toString()
                                    if (ctx?.route.params.actionType === 'copy') label = i18n.t('new_post').toString()
                                    if (ctx?.route.params.actionType === 'edit') label = i18n.t('edit_post').toString()
                                    if (ctx?.route.params.actionType === 'edit-published') label = i18n.t('edit_published_post').toString()
                                    if (ctx?.route.params.actionType === 'show-suggest') label = i18n.t('show_suggest').toString()
                                    if (ctx?.route.params.actionType === 'edit-suggest') label = i18n.t('edit_suggest').toString()

                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_posts_planner_title'), routeName: 'posts_planner' },
                                        { label }
                                    ]
                                },
                            },
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-shopping-bag',
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Buy_channel',
                            path: '/board/:id/channel/:channelId/buy',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_buy_channel_title') }
                                    ]
                                }
                            },
                            component: (): any => import('./views/buy-channel.vue'),
                        },

                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'invite_link',
                            path: '/board/:id/channel/:channelId/link/:actionType',
                            query: {},
                            component: (): any => import('./views/invite-links/link-page.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseBoardBreadcrumbs(ctx.route),
                                        ...baseChannelBreadcrumbs(ctx.route),
                                        { label: i18n.t('menu_invite_links_title'), routeName: 'invite_links_list' },
                                        ...ctx.route.query.linkCode ? [ { label: ctx.route.query.linkCode.toString().split('.')[0] } ] : [],
                                        { label: actionTypeBreadcrumb(ctx) }
                                    ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
        ],
        globalGuards: {
            afterEach: async (to) => {
                const boardId = to.params.id
                const channelId = to.params.channelId

                if (!boardId)  {
                    await store.dispatch('clearBotStores')
                }

                if (!channelId) {
                    await store.dispatch('reset_active_channel')
                    await store.dispatch('updateHasSelectedPostTargetsLinkedChat', {
                        targets: [],
                        boardId: boardId
                    })
                    await store.dispatch('resetInviteLinks')
                    store.commit('updateValidateCommentButtonHasGroup', false)
                }
            },
            beforeEach: async (to: Route, next: NavigationGuardNext): Promise<void> => {
                const boardId = to.params.id
                const channelId = to.params.channelId

                if (boardId) {
                    store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', boardId)
                }

                if (!store.state.boardsState.boards || to.name === 'Boards') {
                    try {
                        await store.dispatch('requestBoards')
                    } catch (error: any) {
                        next('/')
                    }
                }

                if (store.state.boardsState.boards) {
                    const currentBoard: Board | null | undefined = store.getters.getBoardById(boardId)

                    if (boardId) {
                        if (currentBoard) {
                            if (store.state.boardsState.activeBoard === null) {
                                await store.dispatch('getBoardConfig', currentBoard.board)
                            }

                            if (!store.state.menuOptionsState.menuOptions) {
                                await store.dispatch('getMenuOptions')
                            }

                            if (channelId) {
                                if (!store.state.channelsState.activeChannel) {
                                    try {
                                        await store.dispatch('getChannelConfig', {
                                            board_key: currentBoard.board,
                                            channel_id: Number.parseInt(channelId)
                                        })

                                        await store.dispatch('updateHasSelectedPostTargetsLinkedChat', {
                                            targets: [ channelId ],
                                            boardId: boardId
                                        })
                                        store.commit('updateValidateCommentButtonHasGroup', true)
                                    } catch (e) {
                                        next({ name: 'Channels', params: { id: boardId } })
                                    }
                                }
                            }
                        } else if (currentBoard === undefined) {
                            next({ name: 'Boards' })
                            errorNotification('Wrong board id: ' + boardId)
                        } else {
                            await store.dispatch('requestBoards')
                        }
                    }
                }

                    next()
                },
        },
        onSaveButtonClick: (ctx, wasClicked): void => {
            if (wasClicked) {
                if (ctx.route.params.channelId) {
                    store.dispatch('save_active_channel_config', ctx.route)
                } else {
                    store.dispatch('savePostBoardConfig')
                }
            }
        }
    },
    router: router,
    store: store,
}

Vue.use(AdminTemplate as unknown as PluginFunction<any>, adminTemplateOptions)

Vue.config.productionTip = false;

(window as any).mobilecheck = isMobile

Vue.use(VueCustomElement)

Vue.customElement('my-element', (PostFeed.prototype.constructor as any).options)

export default new Vue({
    router,
    i18n,
    store,
    'render': (h): VNode => h(App),
}).$mount('#app')
