















































































































































































































































import {
  OrderedPostData,
  Poll,
  PostDataStat,
  PostMessage,
  PostState,
  PostType,
  Quiz,
  Schedule,
  SplitType,
  Style
} from '@/includes/types/Post.types'
import Post from './components/post.vue'
import PollQuiz from './components/poll-quiz.vue'
import './TelegramScript.js'
import FeatherIcon from '@/components/FeatherIcon.vue'
import i18n from '@/i18n/i18n'
import PostInfo from './components/PostInfo.vue'
import PostTail from './components/TelegramPostPreviewTail.vue'
import PostButtons from './components/TelegramPostButtons.vue'
import { PostAttachmentsTypes, PostTypes } from './logic/types'
import { SplitTags } from './logic/helpers'
import { PostService } from '@/includes/services/PostService'
import { errorNotification } from '@/includes/services/NotificationService'
import PaidPostPreview from './components/PaidPostPreview.vue'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import FileApi from 'piramis-base-components/src/components/File/logic/file-api'

import { cloneDeep, debounce } from 'lodash'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'

@Component({
  name: 'TelegramPostPreview',
  components: {
    Post,
    PollQuiz,
    FeatherIcon,
    PostInfo,
    PostTail,
    PostButtons,
    PaidPostPreview
  },
  data() {
    return {
      moment,
      i18n,
      PostTypes
    }
  }
})
export default class TelegramPostPreview extends Vue {
  @Prop() boardTitle!: string

  @Prop() targetChannels!: Array<SelectOptionData>

  @Prop() boardKey!: string

  @Prop() schedule!: Schedule | null

  @Prop() message!: PostMessage | Poll | Quiz

  @Prop() postStyle!: Style

  @Prop() stat!: Array<PostDataStat> | undefined

  @Prop({ type: String, required: false }) postState!: PostState

  @Prop() runTime!: string

  @Prop({ default: null, type: String }) splitType!: SplitType | null

  @Prop({ default: true }) firstCall!:boolean

  channelsPostInfo: any | null = null

  postInfoModal = false

  currentPost: null | OrderedPostData = null

  overcrowdingPost = false

  debHandler = debounce(this.messageHandler, 3000)

  isLoading = false

  updatedVariant:{split_type: SplitType} & PostMessage | null = null

  fileApi = new FileApi('api.presscode.app/cabinet')

  get messagePost():any {
    if (!this.overcrowdingPost) {
      if (!this.updatedVariant) {
        return this.message
      } else {
        return this.updatedVariant
      }
    }

    if (this.overcrowdingPost) {
      return this.mainPost
    }
  }

  get messageText():any {
    if (this.updatedVariant) {
      return this.getText(this.updatedVariant.variants[0].text)
    }

    return this.getText((this.message as PostMessage).variants[0].text)
  }

  get messageButtons():any {
    if (this.updatedVariant) {
      return this.updatedVariant.variants[0].buttons
    }

    return (this.message as PostMessage).variants[0].buttons
  }

  get totalCommentCount(): number | null {
    if (this.stat && this.stat.length) {
      return this.stat.reduce((sum, postStat) => sum + postStat.comments_count, 0)
    } else {
      return null
    }
  }

  get totalRepliesCount(): number | null {
    if (this.stat && this.stat.length) {
      return this.stat.reduce((sum, postStat) => sum + postStat.forwards, 0)
    } else {
      return null
    }
  }

  get totalViewsCount(): number | null {
    if (this.stat && this.stat.length) {
      return this.stat.reduce((sum, postStat) => sum + postStat.views, 0)
    } else {
      return null
    }
  }

  get sortChannels(): null | Array<SelectOptionData> {
    if (this.targetChannels) {
      if (this.targetChannels.length > 10) {
        return this.targetChannels.slice(0, 10)
      } else {
        return this.targetChannels
      }
    } else {
      return null
    }
  }

  get filesIncludesPreview(): boolean {
    if ((this.message as PostMessage).variants) {
      return (this.message as PostMessage).variants[0].attachments!.some((attachment) => attachment.type === PostAttachmentsTypes.MessagePreview)
    }
    return false
  }

  get checkReadinessPostPreview(): boolean {
    if (this.overcrowdingPost) {
      if (this.mainPost && this.subPost) {
        return true
      } else {
        return false
      }
    } else {
      if (this.message) {
        return true
      }
      return false
    }
  }

  get mainPost(): any {
    if (this.overcrowdingPost) {
      const message = this.splitType ? this.message : this.updatedVariant
      const messageCopy = cloneDeep(message)

      if (messageCopy && messageCopy.type === PostType.Post) {
        const firstVariant = messageCopy.variants[0]
        const splitType: SplitType = this.splitType ?? this.updatedVariant!.split_type

        if (splitType === SplitType.LastMedia && firstVariant.attachments) {
          firstVariant.attachments.pop()
        }

        firstVariant.text = ''
        firstVariant.buttons = []

        return messageCopy
      }

    }

    return null
  }

  get subPost(): any {
    if (this.overcrowdingPost) {
      const message = this.splitType ? this.message : this.updatedVariant
      const messageCopy = cloneDeep(message)

      if (messageCopy && messageCopy.type === PostType.Post) {
        const firstVariant = messageCopy.variants[0]
        const splitType: SplitType = this.splitType ?? this.updatedVariant!.split_type

        if (splitType === SplitType.LastMedia && firstVariant.attachments && firstVariant.attachments) {
          const lastItem = firstVariant.attachments.pop()

          if (lastItem) {
            firstVariant.attachments = [ lastItem ]
          }
        }

        if (splitType === SplitType.Text) {
          firstVariant.attachments = []
        }

        return messageCopy
      }

    }

    return null
  }

  getPostPreview(): { backgroundImage: string } {
    const previewLink = (this.message as PostMessage).variants[0].attachments!.find((attachment) => attachment.type === PostAttachmentsTypes.MessagePreview)!.file

    return {
      backgroundImage: `url(${ this.fileApi.getApiUrl() }/getfilepreview?file_descriptor=${ previewLink }&target=${ this.boardKey }`
    }
  }

  getText(text: string): string {
    return text.replaceAll('\n', '<br>')
  }

  messageHandler(): void {
    this.checkPostPreview()
  }

  showPostInfo(post: OrderedPostData): void {
    this.currentPost = post
    this.postInfoModal = true
  }

  getPreview(imageLink: string): any {
    return {
      backgroundImage: `url(${ this.fileApi.getApiUrl() }/getfilepreview?file_descriptor=${ imageLink }&target=${ this.boardKey }`
    }
  }

  checkPostPreview(): void {
    PostService.getPostPreview('tg', {
      board_key: this.boardKey,
      post: this.message as PostMessage,
      style: this.postStyle,
      apply_watermark: true
    })
      .then(({ post }) => {
        this.updatedVariant = post

        if ([ SplitType.LastMedia, SplitType.Text ].includes(post.split_type)) {
          this.overcrowdingPost = true
        }
        if (post.split_type === SplitType.None) {
          this.overcrowdingPost = false
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
      })
  }

  overcrowdingCheck(): void {
    if (this.message && this.message.type === PostType.Post) {
      const message = this.message as PostMessage
      const mediaWithoutPreview = message.variants[0].attachments!.some((attachment) => attachment.type !== PostAttachmentsTypes.MessagePreview) && message.variants[0].attachments!.length > 1
      const textLength = SplitTags(message.variants[0].text).length
      const hasButtons = message.variants[0].buttons.length > 0

      if (mediaWithoutPreview) {
        if (textLength > 1014 && textLength < 1034) {
          this.overcrowdingPost = true
        } else if (hasButtons) {
          this.overcrowdingPost = true
        } else {
          this.overcrowdingPost = false
        }
      } else {
        this.overcrowdingPost = false
      }
    }
  }

  messageFieldsUnwatch = () => {}
  styleFieldsUnwatch = () => {}

  beforeDestroy() {
    this.messageFieldsUnwatch()
    this.styleFieldsUnwatch()
  }

  setWatchers():void {
    this.messageFieldsUnwatch = this.$watch('message.variants', () => {
      this.isLoading = true
      this.debHandler()
    }, {
      deep: true,
      immediate: true
    })

    if (this.postStyle) {
      this.styleFieldsUnwatch = this.$watch('postStyle', () => {
        this.isLoading = true
        this.debHandler()
      }, {
        deep: true,
        immediate: false
      })
    }
  }

  mounted(): void {
    this.$nextTick(() => {
      const locale = this.$i18n ? this.$i18n.locale : i18n.locale
      moment.locale(locale)

      if (this.firstCall && !this.splitType && this.message.type === PostType.Post) {
        this.setWatchers()
      }

      if (this.splitType) {
        if ([ SplitType.LastMedia, SplitType.Text ].includes(this.splitType)) {
          this.overcrowdingPost = true
          // this.overcrowdingCheck()
        }
        if (this.splitType === SplitType.None) {
          this.overcrowdingPost = false
          // this.overcrowdingCheck()
        }
      }
    })
  }
}
