import { Commit } from 'vuex'
import { ShopService } from '@/includes/services/ShopService'
import { errorNotification } from '@/includes/services/NotificationService'
import { RootState } from '@/store/state'
import {
	CreateProductModel,
	EditProductModel,
	Product,
	ProductState
} from 'piramis-base-components/src/components/Shop/Products/types'
import {
	CashRegisterModel, CashRegistersState,
	CreateCashRegisterModel, UpdateCashRegisterModel
} from 'piramis-base-components/src/components/Shop/CashRegisters/types'

export default {
	getProducts({ commit, state }: { commit: Commit, state: RootState }): Promise<Array<Product>> {
		return new Promise((resolve, reject) => {
			if (state.shopState.products) {
				resolve(state.shopState.products)
			} else {
				ShopService.getProducts('tg', { board_key: state.boardsState.activeBoard!.board })
					.then(({ items }) => {
						commit('set_products_to_store', items)
						resolve(items)
					})
					.catch((error:any) => {
						reject()
						errorNotification(error)
					})
			}
		})
	},
	createProduct({ commit, state }: { commit: Commit, state: RootState }, product: CreateProductModel): Promise<Product> {
		return new Promise((resolve, reject) => {
			ShopService.createProduct('tg', Object.assign({ board_key: state.boardsState.activeBoard!.board }, product))
				.then(({ data }) => {
					commit('set_product_to_store', data)
					resolve(data)
				})
				.catch((error:any) => {
					reject()
					errorNotification(error)
				})
		})
	},
	editProduct({ commit, state }: { commit: Commit, state: RootState }, product: EditProductModel): Promise<Product> {
		return new Promise((resolve, reject) => {
			ShopService.editProduct('tg', Object.assign(product, { board_key: state.boardsState.activeBoard!.board }))
				.then(({ data }) => {
					commit('edit_product_in_store', data)
					resolve(data)
				})
				.catch((error: any) => {
					reject()
					errorNotification(error)
				})
		})
	},
	deleteProduct({ commit, state }: { commit: Commit, state: RootState }, id: number): Promise<ProductState.Removed | ProductState.Hidden> {
		return new Promise((resolve, reject) => {
			ShopService.deleteProduct('tg', { board_key: state.boardsState.activeBoard!.board, id })
				.then(({ state }) => {
					commit('delete_product_from_store', { state, id })
					resolve(state)
				})
				.catch((error: any) => {
					reject()
					errorNotification(error)
				})
		})
	},
	restoreProduct({ commit, state }: { commit: Commit, state: RootState }, id: number): Promise<void> {
		return new Promise(resolve => {
			ShopService.restoreProduct('tg', { board_key: state.boardsState.activeBoard!.board, id })
				.then(() => {
					commit('restore_product', id)
					resolve()
				})
		})
	},
	getCashRegisters({ commit, state }: { commit: Commit, state: RootState }): Promise<Array<CashRegisterModel>> {
		return new Promise((resolve, reject) => {
			ShopService.getCashRegistries('tg', { board_key: state.boardsState.activeBoard!.board })
				.then(({ items }) => {
					commit('set_cash_registries_to_store', items)
					resolve(items)
				})
				.catch((error: any) => {
					reject()
					errorNotification(error)
				})
		})
	},
	createCashRegister({ commit, state }: { commit: Commit, state: RootState }, cashRegister: CreateCashRegisterModel): Promise<CashRegisterModel> {
		return new Promise((resolve, reject) => {
			ShopService.createCashRegister('tg', Object.assign({ board_key: state.boardsState.activeBoard!.board }, cashRegister))
				.then(({ data }) => {
					commit('set_cash_register_to_store', data)
					resolve(data)
				})
				.catch((error:any) => {
					reject()
					errorNotification(error)
				})
		})
	},
	editCashRegister({ commit, state }: { commit: Commit, state: RootState }, cashRegister: UpdateCashRegisterModel): Promise<CashRegisterModel> {
		return new Promise((resolve, reject) => {
			ShopService.editCashRegister('tg', Object.assign({ board_key: state.boardsState.activeBoard!.board }, cashRegister))
				.then(({ data }) => {
					commit('edit_cash_register_in_store', data)
					resolve(data)
				})
				.catch((error:any) => {
					reject()
					errorNotification(error)
				})
		})
	},
	deleteCashRegister({ commit, state }: { commit: Commit, state: RootState }, id: number): Promise<CashRegistersState> {
		return new Promise((resolve, reject) => {
			ShopService.deleteCashRegister('tg', Object.assign({ board_key: state.boardsState.activeBoard!.board }, { id }))
				.then(({ state }) => {
					commit('delete_cash_register_from_store', { id, cashState: state })
					resolve(state)
				})
				.catch((error:any) => {
					reject()
					errorNotification(error)
				})
		})
	},
}
