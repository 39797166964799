import BaseApi from '@/includes/services/BaseApi'
import { Board, ChannelSlots, Target, TargetConfig, TargetState, TestChannelStateCode } from '@/includes/types/Board.types'
import {
    ClearUsersGetActiveRequestResponse,
    ClearUsersRequest,
    ClearUsersRequestBody,
} from '../types/Channel.types'

export class ChannelService {
    static updateChannelState(type: string, body: { board_key: string, channel_id: number, new_state: TargetState }): Promise<Board> {
        return BaseApi.sendRequest(type, 'updatechannelstate', body)
    }

    static getChannelConfig(type: string, body: { board_key: string, channel_id: number }): Promise<Target> {
        return BaseApi.sendRequest(type, 'getChannelConfig', body)
    }

    static setChannelConfig(type: string, body: { board_key: string, channel_id: number, config: TargetConfig }): Promise<Target> {
        return BaseApi.sendRequest(type, 'setChannelConfig', body)
    }

    static getChannelStat(type: string, body: { board_key: string, target_id: number, interval: any }): Promise<any> {
        return BaseApi.sendRequest(type, 'getchannelstat', body)
    }

    static testChannel(type: string, body: { board_key: string, channel_id: number }): Promise<{status: TestChannelStateCode}> {
        return BaseApi.sendRequest(type, 'testchannel', body)
    }

    static getChannelSlots(type: string, body: { board_key: string, channel_ids: Array<number> }): Promise<{items: Array<ChannelSlots>}> {
        return BaseApi.sendRequest(type, 'getchannelslots', body)
    }

    static clearUsersRequest(type: string, body: ClearUsersRequestBody): Promise<ClearUsersGetActiveRequestResponse | ClearUsersRequest> {
        return BaseApi.sendRequest(type, 'ClearUsersRequest', body)
    }
}
