import { BoardService } from '@/includes/services/BoardService'
import { Board, BoardAdmins, BoardConfig } from '@/includes/types/Board.types'

import { Commit, Dispatch } from 'vuex'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { RootState } from '@/store/state'

export default {
    createBoard({ commit }: { commit: Commit }, body: { token: string, timezone:string }): Promise<Board> {
        return new Promise((resolve, reject) => {
            BoardService.createBoard('tg', body)
              .then((board) => {
                  commit('set_board_to_store', board)
                  resolve(board)
              })
              .catch((error) => {
                  errorNotification(error)
                  reject()
              })
        })
    },
    requestBoards({ commit }: { commit: Commit }): Promise<Array<Board> | void> {
            return BoardService.getBoards('tg')
                .then((data) => {
                    commit('set_boards_to_store', data.boards)

                    return data.boards
                })
                .catch(errorNotification)
    },
    getBoardConfig({ commit }:{commit:Commit}, board_key:string):Promise<BoardConfig> {
        return new Promise((resolve, reject) => {
            BoardService.getPostBoardConfig('tg', { board_key })
                .then(boardConfig => {
                    commit('set_active_board_config', boardConfig)
                    resolve(boardConfig)
                })
                .catch(error => {
                    errorNotification(error)
                    reject(error)
                })
        })
    },
    savePostBoardConfig({ commit, state, dispatch }: { commit: Commit, state:RootState, dispatch: Dispatch }) {
        return BoardService.setPostBoardConfig('tg', {
            board_key: state.boardsState.activeBoard!.board,
            config: state.boardsState.activeBoard!.config,
            lang: state.boardsState.activeBoard!.lang,
            timezone: state.boardsState.activeBoard!.timezone
        })
            .then((resConfig) => {
                commit('set_active_board_config', resConfig)
                successNotification()
            })
            .then(() => {
                return dispatch('getBoardConfig', state.boardsState.activeBoard!.board)
            })
            .catch(errorNotification)

    },
    updateBoardAdmins({ commit }: {commit: Commit}, boardAdmins: BoardAdmins):Promise<void> {
        return new Promise((resolve, reject) => {
            commit('', boardAdmins)
        })
    },
    //action replies
    addActionRepliesTrigger({ commit }: {commit: Commit}, newTrigger:any) {
        commit('add_reply_action', newTrigger)
    },
    updateActionRepliesTrigger({ commit }: {commit: Commit}, { index, newTrigger }: {index: number, newTrigger:any}) {
        commit('update_reply_action', { index, newTrigger })
    },
    removeActionRepliesTrigger({ commit }: {commit: Commit}, { index }: {index: number}) {
        commit('remove_reply_action', { index })
    },
    //commands
    addCommandsTrigger({ commit }: {commit: Commit}, newTrigger:any) {
        commit('add_pm_command', newTrigger)
    },
    updateCommandsTrigger({ commit }: {commit: Commit}, { index, newTrigger }: {index: number, newTrigger:any}) {
        commit('update_pm_command', { index, newTrigger })
    },
    removeCommandsTrigger({ commit }: {commit: Commit}, { index }: {index: number}) {
        commit('remove_pm_command', { index })
    }
}
