import { Board, BoardConfig } from '@/includes/types/Board.types'

export type BoardsState = {
  boards:Array<Board> | null
  activeBoard:BoardConfig | null
}

export const boardsState:BoardsState ={
  boards: null,
  activeBoard: null,
}
