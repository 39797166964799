import BaseApi from '@/includes/services/BaseApi'
import {
    Board, BoardAdmin,
    BoardAdminPermissions,
    BoardAdmins,
    BoardConfig,
    Target,
    TestBoardStateCode
} from '@/includes/types/Board.types'

export class BoardService {
    static createBoard(type: string, body: { token: string, timezone:string }): Promise<Board> {
        return BaseApi.sendRequest(type, 'createBoard', body)
    }

    static deleteBoard(type: string, body: { board_key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'deleteboard', body)
    }

    static getBoards(type: string): Promise<{ boards: Array<Board> }> {
        return BaseApi.sendRequest(type, 'getBoards', undefined)
    }

    static getBoardChannels(type: string, body: { board_key: string }): Promise<{ board: Board, channels: Array<Target> }> {
        return BaseApi.sendRequest(type, 'getBoardChannels', body)
    }

    static getPostBoardConfig(type: string, body: { board_key: string }): Promise<BoardConfig> {
        return BaseApi.sendRequest(type, 'getPostBoardConfig', body)
    }

    static setPostBoardConfig(type: string, body: { board_key: string } & Pick<BoardConfig, 'lang' | 'timezone' | 'config'>): Promise<BoardConfig> {
        return BaseApi.sendRequest(type, 'setPostBoardConfig', body)
    }

    static getBoardsStat(type: string, body: {}): Promise<{ boards: Array<Board> }> {
        return BaseApi.sendRequest(type, 'getBoardsStat', body)
    }

    static addBoardAdminByLink(type: string, body: { board_key: string, permissions: BoardAdminPermissions, channel_ids: Array<number> | null, token?:string }):
        Promise<{link?:string, token?:string, complete?:boolean, admins?:Array<BoardAdmin>}>
    {
        return BaseApi.sendRequest(type, 'addboardadminbylink', body)
    }

    static addBoardAdminByName(type: string, body: { board_key: string, username: string,  permissions: BoardAdminPermissions, channel_ids: Array<number> | null }): Promise<BoardAdmins> {
        return BaseApi.sendRequest(type, 'addboardadminbyname', body)
    }

    static deleteBoardAdmin(type: string, body: { board_key: string, user_id: number }): Promise<BoardAdmins> {
        return BaseApi.sendRequest(type, 'deleteboardadmin', body)
    }

    static updateBoardAdmin(type: string, body: { board_key: string, user_id: number, permissions: BoardAdminPermissions, channel_ids: Array<number> | null }): Promise<{ admins: BoardAdmins }> {
        return BaseApi.sendRequest(type, 'updateboardadmin', body)
    }

    static testPostBoard(type: string, body: { board_key: string }): Promise<{ status: TestBoardStateCode }> {
        return BaseApi.sendRequest(type, 'testpostboard', body)
    }
}
