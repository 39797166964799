import { SelectOption } from 'piramis-base-components/src/logic/types'
import { RootState } from '@/store/state'
import i18n from '@/i18n/i18n'
import {
    PlaceholdersOnes,
    PlaceholdersType
} from 'piramis-base-components/src/components/Editor/components/Toolbar/types'
import store from '@/store/store'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'

export default {
    hasFlows: (state: RootState): boolean => {
        if (state.flowsState.flows === null) {
            return store.getters.getMenuOptionValue(MenuOptionsKeys.Flows)
        }

        return !!state.flowsState.flows && state.flowsState.flows.length > 0
    },
    flowOptions: (state: RootState, getters): Array<SelectOption> => {
        if (state.flowsState.flows) {

            return state.flowsState.flows!.slice().map(f => ({
                label: f.title,
                value: f.key
            }))
        }

        return []
    },
    flowPlaceholders: (state: RootState): PlaceholdersOnes => {
        return {
            type: PlaceholdersType.Ones,
            placeholders: [
                {
                    label: i18n.t('user_placeholder_username').toString(),
                    value: '{username}'
                },
                {
                    label: i18n.t('user_placeholder_user_id').toString(),
                    value: '{user_id}'
                },
                ...state.boardsState.activeBoard!.config.properties.map((p) => {
                    return {
                        label: p.name,
                        value: `{${ p.name }}`,
                    }
                })
            ]
        }
    },

    botTargets: (state: RootState, getters: any): Array<{ title: string, login: string, id: number }> => {
        const id = state.boardsState.activeBoard!.board.split('.')[0]

        const board = getters.getBoardById(id)

        if (board) {
            return board.targets.map((target) => {
                return {
                    title: target.title,
                    login: target.username,
                    id: target.id
                }
            })
        }

        return []
    },
}
