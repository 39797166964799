import { ButtonConfig } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'

export type OrderedPostData = PostData & { stat: Array<PostDataStat> }

export type PostDataStat = {
    channel_id: number | null
    chat_id: number | null
    comments_count: number
    comments_link: string
    first_12_hour_forwards: number
    first_12_hour_views: number
    first_24_hour_forwards: number
    first_24_hour_views: number
    first_4_hour_forwards: number
    first_4_hour_views: number
    first_7_days_forwards: number
    first_7_days_views: number
    first_hour_forwards: number
    first_hour_views: number
    forwards: number
    post_link: string
    views: number
}

export enum PostState {
    New = 'New',
    Error = 'Error',
    Complete = 'Complete',
    Active = 'Active',
    Deleted = 'Deleted',
    PublishedDeleted = 'PublishedDeleted',
    Rejected = 'Rejected'
}

export type PostData = {
    post: IPost
    board_key?: string
    schedule: Schedule
    tags?: Array<string>
    category?: any
    creative_id?: any
    state: PostState
    key: string
    targets: Array<number | 'ALL_TARGETS'>
    run_time: Array<string> | string
    origin?: number | null
    style: Style
    color: string | null
    split_type: SplitType
    has_posts_outside_interval: boolean
}

export type RequestPost = Pick<PostData,
    'board_key' | 'origin' | 'post' | 'schedule' | 'style' | 'targets' | 'tags'>
export type RequestPostDraft = { board_key: string, message: PostMessage | Poll | Quiz | PayedPostMessage }

export type OriginalPostResponse = {
    message: IPost,
    target: {
        targets: Array<number>,
        original: number | null,
        user_data: {
            post_price: number
            disable_comments: boolean
        },
    },
    schedule: Schedule,
    style: Style
    tags: Array<string>
}

export enum PostCommentsStateTypes {
    None = 'None',
    Disabled = 'Disabled',
    Linear = 'Linear'
}

export type StyleData = {
    post_price: number
    disable_comments?: boolean
    color: string | null
    post_comments_state: PostCommentsStateTypes
}

export type Style = {
    id?: number | null,
    compress_level: null | 4320 | 2160 | 1440 | 1080 | 720 | 576 | 480 | 360 | 240
    watermark_type?: WatermarkType | null
    watermark_data?: any
    watermark_position?: WatermarkPosition | null
    prefix_message?: Array<MessageVariants> | null
    postfix_message?: Array<MessageVariants> | null
    actions: Array<PostAction>
    protect_content?: boolean | null
    disable_notify?: boolean | null
    disable_link_preview?: boolean | null
    pin?: boolean | null
    data?: StyleData | null
}

export enum WatermarkType {
    None = 'None',
    File = 'File',
    Text = 'Text'
}

export enum WatermarkPosition {
    Flip = 'Flip',
    Center = 'Center',
    TopRight = 'TopRight',
    TopLeft = 'TopLeft',
    BottomRight = 'BottomRight',
    BottomLeft = 'BottomLeft',
}

export type Schedule = {
    run_time: string
    period: Period | null
    timezone: string
}

export type Conditions = {
    type: string
    values: any
}

export type Period = {
    interval: number | null
    unit: string
    conditions: Array<Conditions> | null
}

export type PayedPostMessage = {
    type: PostType.Paid
    variants: Array<MessageVariants>
    product_id: null | number
    cash_register_id: null | number
}

export type IPost = {
    delayed_actions: Array<PostAction> | null
    reaction_actions: Array<PostReaction> | null
    message: PostMessage | Poll | Quiz | PayedPostMessage
}

export type PostAction = {
    delay: number,
} & IPostActionMessage

export type PostReaction = {
    reaction: string,
    lower_bound: number,
} & IPostActionMessage

export type IPostActionMessage = {
    action: PostActions
}

export enum ForwarMessageActionMode {
    Forward = 'Forward',
    Copy = 'Copy'
}

export type PostActions =
      SendCommentAction
    | EditPostAction
    | SendReplyCommentAction
    | SendReplyMessageAction
    | NotifyUserAction
    | NotifyUsersAction
    | ForwardMessageAction
    | DeletePostAction
    | PinMessageAction
    | PinSilentMessageAction
    | UnpinMessageAction
    | PinChatMessageAction
    | PinChatSilentMessageAction
    | UnpinChatMessageAction

export type SendCommentAction = {
    type: PostActionType.SendCommentAction,
    message: SendCommentActionMessageObject
}

export type EditPostAction = {
    type: PostActionType.EditPostAction,
    message: SendCommentActionMessageObject
}

export type SendReplyCommentAction = {
    type: PostActionType.SendReplyCommentAction,
    message: PostMessage
}

export type SendReplyMessageAction = {
    type: PostActionType.SendReplyMessageAction,
    message: PostMessage
}

export type NotifyUserAction = {
    type: PostActionType.NotifyUserAction
    message:string
    users: Array<number>
}

export type NotifyUsersAction = {
    type: PostActionType.NotifyUsersAction
    message:Array<MessageVariants>
    users: Array<number>
}

export type ForwardMessageAction = {
    type: PostActionType.ForwardMessageAction
    mode: ForwarMessageActionMode,
    targets:  Array<number>
}

export type DeletePostAction = {
    type: PostActionType.DeletePostAction
}

export type PinMessageAction = {
    type: PostActionType.PinMessageAction
}

export type PinSilentMessageAction = {
    type: PostActionType.PinSilentMessageAction
}

export type UnpinMessageAction = {
    type: PostActionType.UnpinMessageAction
}
export type PinChatMessageAction = {
    type: PostActionType.PinChatMessageAction
}

export type PinChatSilentMessageAction = {
    type: PostActionType.PinChatSilentMessageAction
}

export type UnpinChatMessageAction = {
    type: PostActionType.UnpinChatMessageAction
}

export enum PostActionType {
    SendCommentAction = 'SendCommentAction',
    EditPostAction = 'EditPostAction',
    SendReplyCommentAction = 'SendReplyCommentAction',
    SendReplyMessageAction = 'SendReplyMessageAction',

    //
    NotifyUserAction = 'NotifyUserAction',
    NotifyUsersAction = 'NotifyUsersAction',
    //

    ForwardMessageAction = 'ForwardMessageAction',

    DeletePostAction = 'DeletePostAction',
    PinMessageAction = 'PinMessageAction',
    PinSilentMessageAction = 'PinSilentMessageAction',
    UnpinMessageAction = 'UnpinMessageAction',
    PinChatMessageAction = 'PinChatMessageAction',
    PinChatSilentMessageAction = 'PinChatSilentMessageAction',
    UnpinChatMessageAction = 'UnpinChatMessageAction'
}

export type PostMessage = {
    type: PostType.Post
    variants: Array<MessageVariants>
}

export type SendCommentActionMessageObject = {
    type: PostType.Post,
    variants: Array<Omit<MessageVariants, 'pin' | 'remove_previous' | 'disable_notify'>>
}

export type Attachments = {
    type: string
    file: string
}

export type MessageVariants = {
    text: string
    buttons: Array<ButtonConfig>
    attachments?: Array<Attachments>,
    remove_previous: boolean
    pin: boolean
    disable_notify: boolean
    disable_link_preview: boolean
}

export type PostQuestionnaire = {
    text: string,
    questions: Array<string>,
    is_anonymous: boolean,
    open_period: number
}

export type Poll = {
    type: PostType.Poll
    allows_multiple_answers: boolean
} & PostQuestionnaire

export type Quiz = {
    type: PostType.Quiz
    correct_option_id: string,
    explanation: string
} & PostQuestionnaire

export enum ScheduleType {
    Suggest = 'Suggest',
    Schedule = 'Schedule',
    PostNow = 'PostNow',
    Draft = 'Draft',
    Test = 'Test'
}

export enum PostType {
    Poll = 'Poll',
    Post = 'Post',
    Paid = 'Paid',
    Quiz = 'Quiz'
}

export type PickerColors = { color: string, className: string }

export const ColorPickerColors: Array<PickerColors> = [
    { color: '#EECF6D', className: 'yellow' },
    { color: '#8B6220', className: 'brown' },
    { color: '#048A81', className: 'cyan' },
    { color: '#FFB7C3', className: 'pink' },
    { color: '#171d1c', className: 'black' },
    { color: '#b1a7a6', className: 'gray' }
]

export enum ProcessSuggestPostAction {
    Accept  = 'Accept',
    AcceptWithSlot = 'AcceptWithSlot',
    Reject = 'Reject'
}

export enum OrderedPostType {
    Active = 'Active',
    ActiveAndCompleted = 'ActiveAndCompleted',
    Deleted = 'Deleted',
    Suggest = 'Suggest'
}

export enum SplitType {
    None = 'None',
    LastMedia = 'LastMedia',
    Text = 'Text'
}