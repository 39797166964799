





























































































import i18n from "@/i18n/i18n";
import { PostAttachmentsTypes } from '../logic/types'

import { Component, Prop } from "vue-property-decorator";
import Vue from 'vue'
import * as d3 from 'd3'

@Component({
  data() {
    return {
      i18n,
      PostAttachmentsTypes
    }
  }
})
export default class TelegramPostPreviewAttachment extends Vue {
  @Prop() attachment!: { file: string, type: string }

  @Prop() boardKey!: string

  imageAttachments = [
    PostAttachmentsTypes.Image,
    PostAttachmentsTypes.Video,
    PostAttachmentsTypes.Animation
  ]

  getPreview(attachmentKey: string): { backgroundImage: string } {
    return { backgroundImage: `url(https://api.presscode.app/cabinet/v1/tg/getfilepreview?file_descriptor=${ attachmentKey }&target=${ this.boardKey })` }
  }

  mounted(): void {
    if (this.attachment.type === 'Voice') {
      const body = this.$refs[`custom-bars-${ this.attachment.file }`]
      const container = d3.select(body)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '14px')

      const barsBody = container.append('g')
        .attr('id', 'bars-body')

      const width = container.node().clientWidth
      const barsCount = Math.floor(width / 6)

      const xScale = d3.scaleLinear()
        .range([ 0, width ])
        .domain([ 0, barsCount ])

      for (let i = 0; i <= barsCount; i++) {
        const height = Math.random() * (14 - 3) + 3
        barsBody.append('rect')
          .attr('x', () => xScale(i))
          .attr('y', 14 - height)
          .attr('width', 4)
          .attr('height', () => height)
          .attr('fill', '#D3DBE4')
          .attr('rx', 2)
          .attr('ry', 2)
      }
    }
  }
}
