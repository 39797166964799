import { Commit, Dispatch } from 'vuex'
import { RootState } from '@/store/state'
import { BoardService } from '@/includes/services/BoardService'

export default {
	removeStyle({ commit, state, dispatch }: { commit: Commit, state: RootState, dispatch: Dispatch }, name): Promise<void> {
		return new Promise(resolve => {
			delete state.boardsState.activeBoard!.config.styles![name]

			BoardService.setPostBoardConfig('tg', {
				board_key: state.boardsState.activeBoard!.board,
				config: state.boardsState.activeBoard!.config,
				lang: state.boardsState.activeBoard!.lang,
				timezone: state.boardsState.activeBoard!.timezone
			})
				.then((resConfig) => {
					commit('set_active_board_config', resConfig)
				})
				.then(() => resolve())
		})
	}
}
