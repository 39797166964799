import { Slot, Target } from '@/includes/types/Board.types'

export type ChannelsState = {
    activeChannel: Target | null,
    copiedSlots: Array<Slot> | null
}

export const channelsState: ChannelsState = {
    activeChannel: null,
    copiedSlots: null
}