





































































import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import { Attachments, IPost, MessageVariants, PostMessage } from '@/includes/types/Post.types'
import TelegramPostPreviewAttachment from "@/views/PostFeed/components/TelegramPostPreviewAttachment.vue";

@Component({
  components: {
    TelegramPostPreviewAttachment
  }
})
export default class Post extends Vue {
  @Prop() message!: PostMessage

  @Prop() boardKey!: string

  @Prop() update!: () => void

  @Prop({ required: false }) postsCount?: number

  @Watch('postsCount')
  postsCountWatcher(): void {
    this.updateGroup()
  }

  @Watch('firstMessage.attachments', { deep:true })
  onChange():void {
    this.updateGroup()
  }

  get firstMessage():MessageVariants {
    return this.message.variants[0]
  }

  get hasFilesImageOrVideo (): boolean {
    return this.firstMessage.attachments!.some(file => [ 'Image', 'Video' ].includes(file.type))
  }

  get getText (): string {
    let text = this.firstMessage.text
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    text = text.replaceAll('<b>', '<strong>')
    text = text.replaceAll('</b>', '</strong>')

    return text
  }

  getNotImageOrVideo(arg: string): Array<Attachments> {
    const imageOrVideo: Array<Attachments> = []
    const other: Array<Attachments> = []

    this.firstMessage.attachments!.forEach((attachment) => {
      if ([ 'Image', 'Video' ].includes(attachment.type)) {
        imageOrVideo.push(attachment)
      } else {
        other.push(attachment)
      }
    })

    if(arg === 'Image') {
      return imageOrVideo
    } else {
      return other
    }
  }

  updateGroup (): void {
    this.$nextTick(() => {
      const postBody = this.$refs['attachments'] as Vue
      if (postBody) {
        (this.$refs['attachments'] as any).__inited = false;
        (window as any).TGrouped.init(postBody)
      }
    })
  }

  getPreview(attachmentKey: string): { backgroundImage: string } {
    return { backgroundImage: `url(https://api.presscode.app/cabinet/v1/tg/getfilepreview?file_descriptor=${ attachmentKey }&target=${ this.boardKey }` }
  }

  created(): void {
    const context = this
    if (!(window as any).TGrouped) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://telegram.org/js/widget-frame.js')
      document.head.appendChild(recaptchaScript)
      recaptchaScript.onload = function () {
        (window as any).TWidgetPost.init()
        context.updateGroup()
      }
    } else {
      this.updateGroup()
      this.$nextTick(() => {
        const attachments = this.$refs.attachment
        if (Array.isArray(attachments)) {
          attachments.forEach(attachment => {
            (window as any).TPhoto.init(attachment)
          })
        }
      })
    }
  }
}
