import { RootState } from '@/store/state'
import { FlowsParams } from 'piramis-base-components/src/components/FlowsList/types'

export default {
	SET_FLOWS_TO_STORE(state: RootState, flows: FlowsParams): void {
		state.flowsState.flows = flows
	},
	SET_NEW_FLOW_TO_STORE(state: RootState, newFlow): void {
		state.flowsState.flows!.push(newFlow)
	},
	REMOVE_FLOW_FROM_STORE(state: RootState, flow_key): void {
		const removedFlowIndex = state.flowsState.flows!.findIndex((flow) => flow.key === flow_key)
		state.flowsState.flows?.splice(removedFlowIndex, 1)
	},
	RENAME_FLOW(state: RootState, { title, flow_key }: { flow_key: string, title: string }): void {
		const flow = state.flowsState.flows!.find((f) => f.key === flow_key)

		if (flow) {
			flow.title = title
		}
	},
	CLEAR_FLOWS_FROM_STORE: (state: RootState) => {
		state.flowsState.flows = null
	}
}
