import { RootState } from '@/store/state'
import { NestedBoardConfig } from '@/includes/types/Board.types'
import { Property, PropertyFieldType } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

export default {
	set_property_to_board(state: RootState, property: { id: number, name: string, type: PropertyFieldType }): void {
		const activeBoard = state.boardsState.activeBoard!

		activeBoard.config.properties.push(Object.assign(property, { options: [] }))
	},
	delete_property_from_board(state: RootState, config: NestedBoardConfig): void {
		state.boardsState.activeBoard!.config = config
	},
	set_properties_to_store(state: RootState, properties: Array<Property>): void {
		state.boardsState.activeBoard!.config.properties = properties.map((p) => {
			const { group, ...otherFields } = p

			return otherFields
		})
	}
}
