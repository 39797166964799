import { FlowsService, DeleteFlow } from '@/includes/services/FlowService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import { FlowsParams, FlowParams } from 'piramis-base-components/src/components/FlowsList/types'

import { Commit } from 'vuex'
import { RootState } from '@/store/state'
import i18n from "@/i18n/i18n";

export default {
	requestFlows({ commit, state }: { commit: Commit, state: RootState }): Promise<FlowsParams | void>  {
			return FlowsService.getBoardFlows('tg', { board_key: state.boardsState.activeBoard!.board })
				.then((res) => {
					commit('SET_FLOWS_TO_STORE', res.flows)
					return res.flows
				})
				.catch((error) => {
					errorNotification(error)
					commit('SET_FLOWS_TO_STORE', [])
				})
	},

	createFlow({ commit, state }: { commit: Commit, state: RootState }, { title, scheme }:{ title: string, scheme: any }): Promise<FlowParams> {
		return new Promise(resolve => {
			FlowsService.createFlow('tg', { board_key: state.boardsState.activeBoard!.board, title, scheme })
				.then((flow) => {
					commit('SET_NEW_FLOW_TO_STORE', Object.assign({ title, flow }))
					resolve(flow)
				})
				.catch(errorNotification)
		})
	},

	removeFlow({ commit, state }: { commit: Commit, state: RootState }, flow_key: string): Promise<DeleteFlow> {
		return new Promise(resolve => {
			FlowsService.deleteFlow('tg', { board_key: state.boardsState.activeBoard!.board, flow_key })
				.then((res) => {
					commit('REMOVE_FLOW_FROM_STORE', `${ res.key.token_id }.${ res.key.token_code }`)
					resolve(res)
				})
				.catch(errorNotification)
		})
	},

	renameFlow({ commit, state }: { commit: Commit, state: RootState }, { title, key }: {title: string, key: string}) {
		return new Promise(resolve => {
			FlowsService.renameFlow('tg', { board_key: state.boardsState.activeBoard!.board, title, key })
				.then((res) => {
					commit('RENAME_FLOW', { title, flow_key: key })
					successNotification(i18n.t('flow_name_successfully_updated').toString())
					resolve(res)
				})
		})
	}
}
