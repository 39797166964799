import BaseApi from '@/includes/services/BaseApi'
import axios from 'axios'
import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import { errorNotification } from '@/includes/services/NotificationService'
import { FlowData } from 'piramis-base-components/src/components/Flow/logic/types/GeneralTypes'
import { FlowParams, FlowsParams } from 'piramis-base-components/src/components/FlowsList/types'

export class FlowsService {
	static getFlowThumbnailData(type: string, body: { request: string }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'GetFlowThumbnailData', body)
	}

	static getBoardFlows(type: string, body: { board_key }): Promise<{ flows: FlowsParams }> {
		return BaseApi.sendRequest(type, 'GetBoardFlows', body)
	}

	static createFlow(type: string, body: { board_key: string, title: string, scheme: any }): Promise<FlowParams> {
		return BaseApi.sendRequest(type, 'CreateFlow', body)
	}

	static getFlow(type: string, body: { board_key: string, flow_key: string }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'GetFlow', body)
	}

	static deleteFlow(type: string, body: { board_key: string, flow_key: string }): Promise<DeleteFlow> {
		return BaseApi.sendRequest(type, 'DeleteFlow', body)
	}

	static updateFlow(type: string, body: { board_key: string, flow_key: string, title: string, scheme: any }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'UpdateFlow', body)
	}

	static getFlowThumbnail(type: string, body: { board_key: string, flow_key: string }): Promise<any> {
		return new Promise((resolve) => {
			axios.get(`${ PostApi.getApiUrl() }${ type }/GetFlowThumbnail?board_key=${ body.board_key }&flow_key=${ body.flow_key }`, {
				withCredentials: true,
			}).then((res) => {
				resolve(res)
			}).catch((error) => {
				errorNotification(error)
			})
		})
	}

	static checkChannels(type: string, body: { board_key: string, values: Array<string> }): Promise<CheckChannelsResponse> {
		return BaseApi.sendRequest(type, 'CheckChannels', body)
	}

	static getUsersInfo(type: string, body: { board_key: string, values: Array<string | number> }): Promise<GetUsersInfoResponse> {
		return BaseApi.sendRequest(type, 'GetUsersInfo', body)
	}

	static createFlowLink(type: string, body: { board_key: string, flow_key: string, utm: string }):Promise<FlowParams> {
		return BaseApi.sendRequest(type, 'CreateFlowLink', body)
	}

	static renameFlow(type: string, body: { board_key: string, key: string, title: string }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'RenameFlow', body)
	}

	static runTestingFlow(type: string, body: { board_key: string, flow_key: string }): Promise<void> {
		return BaseApi.sendRequest(type, 'RunFlow', body)
	}
}

export type SubscriptionTarget = SuccessTarget | FailTarget

export type SuccessTarget = {
	status: true
	info : {
		id: number
		login: string
		title: string
		type: string
	}
}

export type FailTarget = {
	status: false
	error: string
}

export type SuccessGetUser = {
	value: string | number
	status: true
	info: {
		id: number
		name: string
		login: string
		language: string
	}
}

export type CheckChannelsResponse = {
	data: Array<{value: string} & SubscriptionTarget>
}

export type GetUsersInfoResponse = {
	data: Array<Omit<SuccessGetUser, 'status'>>
}

export type FlowResponse = {
	flow: FlowData
}

export type DeleteFlow = {
	key: {
		token_id: number
		token_code: string
	}
}
