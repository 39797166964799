import { render, staticRenderFns } from "./TelegramPostButtons.vue?vue&type=template&id=3163a8d0&scoped=true&"
import script from "./TelegramPostButtons.vue?vue&type=script&lang=ts&"
export * from "./TelegramPostButtons.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3163a8d0",
  null
  
)

export default component.exports