import { RootState } from '@/store/state'
import { Board, BoardAdmin, BoardConfig } from '@/includes/types/Board.types'
import { AutoReplyAction } from 'piramis-base-components/src/components/BotFeedback/AutoReplyActions/types'
import { PmConfig } from '@/includes/types/PmConfig.types'

export default {
    set_boards_to_store(state: RootState, boards: Array<Board>): void {
        state.boardsState.boards = boards
    },
    set_board_to_store(state: RootState, board: Board): void {
        const storeBoards = state.boardsState.boards

        if (storeBoards) {
            const index = storeBoards.findIndex((b) => b.board === board.board)

            if (index !== -1) {
                storeBoards.splice(index, 1, board)
            } else {
                storeBoards.push(board)
            }
        }
    },
    set_active_board_config(state: RootState, payload: BoardConfig): void {
        state.boardsState.activeBoard = payload
    },
    clear_active_board_config(state: RootState): void {
        state.boardsState.activeBoard = null
    },
    update_board_admins(state: RootState, boardAdmins: Array<BoardAdmin>): void {
        if (state.boardsState.activeBoard) {
            state.boardsState.activeBoard.admins = boardAdmins
        }
    },
    // update_reply_action(state: RootState, { index, newTrigger }: { index: number, newTrigger: any }): void {
    //     if (state.boardsState.activeBoard && state.boardsState.activeBoard.config.pm_config !== null) {
    //         state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions?.splice(index, 1, newTrigger)
    //     }
    // },
    // remove_reply_action(state: RootState, { index }: { index: number }): void {
    //     if (state.boardsState.activeBoard && state.boardsState.activeBoard.config.pm_config !== null) {
    //         state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions?.splice(index, 1)
    //     }
    // },
    // add_reply_action(state: RootState, newTrigger: any): void {
    //     if (state.boardsState.activeBoard && state.boardsState.activeBoard.config.pm_config !== null) {
    //         state.boardsState.activeBoard.config.pm_config.user_auto_reply_actions?.push(newTrigger)
    //     }
    // },
    // update_pm_command(state: RootState, { index, newTrigger }: { index: number, newTrigger: any }): void {
    //     if (state.boardsState.activeBoard && state.boardsState.activeBoard.config.pm_config !== null) {
    //         state.boardsState.activeBoard.config.pm_config.commands?.splice(index, 1, newTrigger)
    //     }
    // },
    // remove_pm_command(state: RootState, { index }: { index: number }): void {
    //     if (state.boardsState.activeBoard && state.boardsState.activeBoard.config.pm_config !== null) {
    //         state.boardsState.activeBoard.config.pm_config.commands?.splice(index, 1)
    //     }
    // },
    // add_pm_command(state: RootState, newTrigger: any): void {
    //     if (state.boardsState.activeBoard && state.boardsState.activeBoard.config.pm_config !== null) {
    //         state.boardsState.activeBoard.config.pm_config.commands?.push(newTrigger)
    //     }
    // },
    set_temp_trigger(state: RootState, trigger: any): void {
        state.tempTrigger = trigger
    },
    reset_temp_trigger(state: RootState): void {
        state.tempTrigger = null
    },
    update_board_channel_sub_type(state: RootState, payload: any): void {
        const board = state.boardsState.boards!.find(b => b.board === payload.boardKey)

        if (board) {
            const target = board.targets.find(t => t.id === payload.target.id)

            if (target) {
                target.limited_subscription_type = payload.target.limited_subscription_type
            }
        }

    },

    update_reply_action(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.user_auto_reply_actions) {
            const commands = board.config.pm_config.user_auto_reply_actions
            const index = commands.findIndex((c) => c.guid === command.guid)

            if (index !== -1) {
                commands.splice(index, 1, command)
            } else {
                commands.push(command)
            }
        }
    },
    remove_reply_action(state: RootState, guid: string): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.user_auto_reply_actions) {
            const commands = board.config.pm_config.user_auto_reply_actions
            const index = commands.findIndex((c) => c.guid === guid)

            if (index !== -1) {
                commands.splice(index, 1)
            }
        }
    },
    add_reply_action(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.user_auto_reply_actions) {
            board.config.pm_config.user_auto_reply_actions.push(command)
        }
    },
    update_pm_command(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.commands) {
            const commands = board.config.pm_config.commands
            const index = commands.findIndex((c) => c.guid === command.guid)

            if (index !== -1) {
                commands.splice(index, 1, command)
            } else {
                commands.push(command)
            }
        }
    },
    remove_pm_command(state: RootState, guid: string): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.commands) {
            const commands = board.config.pm_config.commands
            const index = commands.findIndex((c) => c.guid === guid)

            if (index !== -1) {
                commands.splice(index, 1)
            }
        }
    },
    add_pm_command(state: RootState, command: AutoReplyAction): void {
        const board = state.boardsState.activeBoard

        if (board && board.config && board.config.pm_config.commands) {
            board.config.pm_config.commands.push(command)
        }
    },
    set_private_message_settings(state: RootState, config: PmConfig): void {
        state.boardsState.activeBoard!.config.pm_config = config
    }
}
