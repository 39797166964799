import { Commit } from 'vuex'
import { RootState } from '@/store/state'
import { InviteLinksService } from '@/includes/services/InviteLinksService'
import { InviteLink, ListInviteLinkModel } from '@/components/InviteLinks/types'
import { errorNotificationWrapper } from "piramis-base-components/src/logic/helpers/NotificationService";
import i18n from "@/i18n/i18n";

export default {
	getInviteLinks({ commit, state }: { commit: Commit, state: RootState }): Promise<Array<ListInviteLinkModel>> {
		return new Promise(resolve => {
			InviteLinksService.getInviteLinks('tg', { board_key: state.boardsState.activeBoard!.board, target_id: state.channelsState.activeChannel!.id })
				.then(({ links }) => {
					commit('setInviteLinksToStore', links)
					resolve(links)
				})
		})
	},
	deleteInviteLink({ commit, state }: { commit: Commit, state: RootState }, key: string): Promise<void> {
		return InviteLinksService.deleteInviteLink('tg', {
			board_key: state.boardsState.activeBoard!.board,
			link_key: key
		})
			.then(() => commit('deleterInviteLink', key))
	},
	createInviteLink({ commit, state }: { commit: Commit, state: RootState }, link: InviteLink): Promise<ListInviteLinkModel> {
		return new Promise(resolve => {
			InviteLinksService.createInviteLink('tg', {
				board_key: state.boardsState.activeBoard!.board,
				target_id: state.channelsState.activeChannel!.id,
				...link
			})
				.then((res) => {
					commit('addInviteLink', res)
					resolve(res)
				})
				.catch((e) => errorNotificationWrapper(i18n.t('error').toString(), e))
		})
	},
	editInviteLink({ commit, state }: { commit: Commit, state: RootState }, { link, link_key }: { link: InviteLink, link_key: string }): Promise<ListInviteLinkModel> {
		return new Promise((resolve) => {
			InviteLinksService.setInviteLink('tg', {
				board_key: state.boardsState.activeBoard!.board,
				link_key,
				...link
			})
				.then((res) => {
					commit('updateInviteLink', res)
					resolve(res)
				})
				.catch((e) => errorNotificationWrapper(i18n.t('error').toString(), e))
		})
	},
	getInviteLinkModel({ state }: { state: RootState }, key: string): Promise<InviteLink | undefined> {
		return new Promise((resolve) => {
			const fullLink = state.inviteLinksState.links!.find((l) => l.key === key)

			if (fullLink) {
				const { link, enter, leave, kick, key, ignore_invite_protection, ...otherFields } = fullLink

				resolve(otherFields)
			}
			resolve(undefined)
		})
	},
	resetInviteLinks({ commit }: {commit: Commit}) {
		commit('clear_invite_links')
	}
}
