import { RootState } from '@/store/state'
import { Board, Target } from '@/includes/types/Board.types'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { BoardTargetOptionsType, GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import moment from 'moment'

export default {
    hasBoardStyles: (state: RootState) => {
      const board = state.boardsState.activeBoard

      if (board) {
        return board.config.styles && Object.keys(board.config.styles).length
      }

      return false
    },
    getBoardById: (state: RootState): GetBoardByIdType => (boardId: string) => {
        return state.boardsState.boards!.find(b => b.board.split('.')[0] === boardId)
    },
    boardTargetOptions: (state: RootState, getters): BoardTargetOptionsType => (boardId: string): Array<SelectOptionData> => {
        const board: Board | undefined = getters.getBoardById(boardId)

        if (board) {
            return board.targets.map((target: Target) => {
                return {
                    'label': target.title,
                    'value': target.id,
                    'image': {
                        'src': target.avatar
                    }
                }
            })
        }

        return []
    },
    sortedBoards: (state: RootState): Array<Board> => {
        if (state.boardsState.boards) {
            return state.boardsState.boards.sort((a: Board, b: Board) => {
                if (a.targets.length > b.targets.length || a.weight > b.weight) {
                    return -1
                }
                if (a.targets.length < b.targets.length || a.weight < b.weight) {
                    return 1
                }
                return 0
            })
        }

        return []
    },
    stylesOptions: (state: RootState): Array<SelectOptionData> => {
        const board = state.boardsState.activeBoard
        const options: Array<SelectOptionData> = [
            { label: '-', value: '' }
        ]

        if (board?.config.styles && Object.keys(board?.config.styles).length) {
            options.push(
                    ...Object.keys(board?.config.styles).map((styleName: string) => ({
                    label: styleName,
                    value: styleName
                }))
            )

            return options
        }

        return []
    },
    boardLicenseType: (state:RootState) => {
        const board = state.boardsState.activeBoard

        const LICENSE_TYPES = {
            '0': '',
            '1': 'Prime'
        }

        if (board) {
            return LICENSE_TYPES[board.license_type]
        }
    },
    hasBoardLicense: (state:RootState):boolean => {
        if (state.boardsState.activeBoard) {
            return moment(state.boardsState.activeBoard.license_time).isAfter(moment())
        }

        return false
    },
    boardLicenseExpiresSoon: (state:RootState):boolean => {
        if (state.boardsState.activeBoard) {
            return moment(state.boardsState.activeBoard.license_time).diff(moment(), 'd') < 7
        }

        return false
    },
    hasBotModerators: (state:RootState):boolean => {
        if (state.boardsState.activeBoard) {
            return state.boardsState.activeBoard.admins.some((admin) => admin.limits !== null)
        }

        return false
    },
}
