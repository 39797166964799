import { RootState } from '@/store/state'
import boardsMutations 	from "./boards/mutations"
import activeChannelMutations from './channel/mutations'
import articlesMutations from './articles/mutations'
import flowsMutations from './flow/mutations'
import notificationMutations from './notifications/mutations'
import statisticsMutations from './statistics/mutations'
import propertiesMutations from './properties/mutations'
import labelsMutations from './labels/mutations'
import shopMutations from './shop/mutations'
import inviteLinksMutations from './InviteLinks/mutations'
import formsMutations from './forms/mutations'
import draftsMutations from './drafts/mutations'
import menuOptionsMutations from './menuOptions/mutations'

const mutations = {
	updateTestStateCounter(state:RootState) {
		state.testStateCounter ++
	},
	resetTestStateCounter(state:RootState) {
		state.testStateCounter = 0
	},
	updateHasSelectedPostTargetsLinkedChat(state:RootState, payload:boolean) {
		state.hasSelectedPostTargetsLinkedChat = payload
	},
	updateValidateCommentButtonHasGroup(state:RootState, payload:boolean) {
		state.validateCommentButtonHasGroupFields = payload
	},
  	//posts planner
  	...boardsMutations,
	//active channel
	...activeChannelMutations,
	...articlesMutations,
	...flowsMutations,
	...statisticsMutations,
	...notificationMutations,
	...propertiesMutations,
	...labelsMutations,
	...shopMutations,
	...formsMutations,
	...draftsMutations,
	...menuOptionsMutations,
	...inviteLinksMutations,
};

export default mutations;
