import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(VueRouter)

const routes: Array<any> = []

const router = new VueRouter({
  'mode': 'history',
  'base': process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if(to.hash) {
      return { selector: to.hash };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
})

Sentry.init({
  Vue,
  dsn             : "https://956c58894df44cd18a799bc537748a65@cabinet.presscode.app/clientErrorHandler/4",
  integrations    : [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 20.0,
});

export default router
