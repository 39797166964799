import { RootState } from "@/store/state"
import { NestedBoardConfig } from "@/includes/types/Board.types"
import { cloneDeep } from 'lodash'

export default {
	set_label_to_store(state: RootState, { labelId, title }: { labelId: number, title: string }): void {
		if (!state.boardsState.activeBoard!.config.labels) {
			state.boardsState.activeBoard!.config.labels = {}
		}
		const labels = cloneDeep(state.boardsState.activeBoard!.config.labels)

		state.boardsState.activeBoard!.config.labels = Object.assign(labels, { [labelId]: title })
	},
	remove_label_from_store(state: RootState, config: NestedBoardConfig): void {
		state.boardsState.activeBoard!.config = config
	},
	set_labels_to_store(state: RootState, labels: { [key: number]: string }): void {
		state.boardsState.activeBoard!.config.labels = labels
	}
}
