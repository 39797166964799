import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { ChannelService } from '@/includes/services/ChannelService'
import { ChannelSlots, Slot, Target, TargetConfig } from '@/includes/types/Board.types'
import { RootState } from '@/store/state'
import store from '@/store/store'

import { Commit, Dispatch } from 'vuex'
import { Route } from 'vue-router'
import { AxiosError } from 'axios'
import { boardsState } from '@/store/boards/state'

export default {
    async getChannelConfig({ commit }: {commit: Commit}, payload: {board_key:string, channel_id:number}):Promise<void> {
        try {
            const activeChannel = await ChannelService.getChannelConfig('tg', {
                board_key: payload.board_key,
                channel_id: payload.channel_id
            })

            commit('set_active_channel_to_store', activeChannel)
            return Promise.resolve()
        } catch (error:any) {
            if ('isAxiosError' in error) {
                if ((error as AxiosError)!.response!.status === 404) {
                    errorNotification('Wrong channel id: ' + payload.channel_id)
                }
            }
            return Promise.reject()
        }

    },
    set_active_channel({ commit }: { commit: Commit }, payload: Target): void {
        commit('set_active_channel_to_store', payload)
    },
    reset_active_channel({ commit }: { commit: Commit }): void {
        commit('reset_active_channel_from_store')
    },
    updateBoardChannelSubscriptionType({ commit, state }: { commit: Commit, state: RootState }, payload: { boardKey:string, target:Target }):void {
        commit('update_board_channel_sub_type', payload)
    },
    save_active_channel_config({ state, dispatch, getters }: { state: RootState, dispatch: Dispatch, getters: any }, route: Route): void {
        store.commit('AdminTemplate/TOGGLE_GLOBAL_LOADER', true)

        if (state.boardsState.boards) {
            const currentBoard = getters.getBoardById(route.params.id)

            if (currentBoard && state.channelsState.activeChannel) {
                ChannelService.setChannelConfig('tg', {
                    board_key: currentBoard.board,
                    channel_id: Number.parseInt(route.params.channelId),
                    config: state.channelsState.activeChannel.config
                })
                    .then((response) => {
                        successNotification()
                        dispatch('set_active_channel', response)
                            .then(() => {
                                dispatch('updateBoardChannelSubscriptionType', { boardKey: currentBoard.board, target: response })
                            })
                    })
                    .catch(errorNotification)
                    .finally(() => store.commit('AdminTemplate/TOGGLE_GLOBAL_LOADER', false))
            } else {
                throw new Error('Board not found')
            }
        }
    },
    set_copied_slots({ commit }: { commit: Commit }, payload: Array<Slot>): Promise<void> {
        commit('set_copied_slot_to_store', payload)
        return Promise.resolve()
    },
    reset_copied_slots({ commit }: { commit: Commit }, payload: Array<Slot>): Promise<void> {
        commit('reset_copied_slot_from_store', payload)
        return Promise.resolve()
    },
    set_slots_to_config({ commit }: { commit: Commit }, payload: Array<Slot>): Promise<void> {
        commit('set_slots_to_config', payload)
        return Promise.resolve()
    }
}