import { PropertiesService } from '@/includes/services/PropertiesService'
import { Commit } from 'vuex'
import { errorNotification } from '@/includes/services/NotificationService'
import { NestedBoardConfig } from '@/includes/types/Board.types'
import { PropertyFieldType } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import { RootState } from '@/store/state'

export default {
	createProperty({ commit, state }: { commit: Commit, state: RootState }, { name, type }: { name: string, type: PropertyFieldType}): Promise<number> {
		return new Promise(resolve => {
			PropertiesService.createProperty('tg', {
				board_key: state.boardsState.activeBoard!.board,
				type,
				name
			}).then(({ index }) => {
					commit('set_property_to_board', { name, type, id: index })
					resolve(index)
				})
				.catch(errorNotification)
		})
	},

	deleteProperty({ commit }: { commit: Commit }, { board_key, index }: { board_key: string, index: number }): Promise<{config: NestedBoardConfig}> {
		return new Promise(resolve => {
			PropertiesService.deleteProperty('tg', { board_key, index })
				.then(({ config }) => {
					commit('delete_property_from_board', config)
					resolve(config)
				})
		})
	}
}
