import { RootState } from '@/store/state'
import { ListInviteLinkModel } from '@/components/InviteLinks/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

export default {
	inviteLinks(state: RootState): Array<ListInviteLinkModel> {
		return state.inviteLinksState.links ?? []
	},
	inviteLinksOptions(state: RootState): Array<SelectOptionData> {
		if (state.inviteLinksState.links) {
			return state.inviteLinksState.links.map((link) => {
				return {
					label: link.title,
					value: link.key,
				}
			})
		}
		return []
	},
}
