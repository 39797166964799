import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'

export default class BaseApi {
    static sendRequest(type: string, method: string, body: any):Promise<any> {
        return new Promise((resolve, reject) => {
            PostApi.sendRequest(type, method, body)
                .then(({ data }) => {
                    resolve(data)
                }).catch((error: any) => {
                    reject(error)
                })
        })
    }
}
