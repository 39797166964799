import { Commit } from 'vuex'
import { LabelsService } from '@/includes/services/LabelsService'
import { NestedBoardConfig } from '@/includes/types/Board.types'
import { RootState } from '@/store/state'
import i18n from "@/i18n/i18n";

export default {
	createLabel({ commit, state }: { commit: Commit, state: RootState }, title): Promise<{ index: number }> {
		return new Promise((resolve, reject) => {
			const existLabels = Object.values(state.boardsState.activeBoard!.config.labels)

			if (!existLabels.includes(title)) {
				LabelsService.createLabel('tg', {
					board_key: state.boardsState.activeBoard!.board,
					title,
				}).then(({ index }) => {
					commit('set_label_to_store', { labelId: index, title })
					resolve({ index })
				})
			} else {
				reject(i18n.t('label_already_exists').toString())
			}
		})
	},

	deleteLabel({ commit }: { commit: Commit }, { board_key, index }: { board_key: string, index: number }): Promise<{ config: NestedBoardConfig }> {
		return new Promise(resolve => {
			LabelsService.deleteLabel('tg', { board_key, index })
				.then(({ config }) => {
					resolve(config)
					commit('remove_label_from_store', config)
				})
		})
	},
}
