import boardsActions from "./boards/actions"
import activeChannel from './channel/actions'
import articlesActions from './articles/actions'
import flowsActions from './flow/actions'
import statisticsActions from './statistics/actions'
import notificationActions from './notifications/actions'
import propertiesActions from './properties/actions'
import labelsAction from './labels/actions'
import shopAction from './shop/actions'
import styleActions from './styles/actions'
import formsActions from './forms/actions'
import draftsActions from './drafts/actions'
import menuOptionsActions from './menuOptions/actions'
import inviteLinksAction from './InviteLinks/actions'

import { RootState } from '@/store/state'
import { Board } from '@/includes/types/Board.types'
import store from '@/store/store'
import { Commit } from 'vuex'

const actions = {
	updateHasSelectedPostTargetsLinkedChat({ commit, state, getters }: { commit: Commit, state:RootState, getters:any }, payload:{targets: Array<number>, boardId:string}): void {
		if (payload.targets.length) {
			const board:Board = getters.getBoardById(payload.boardId)
			const boardTargets = board.targets.filter(target => payload.targets.includes(target.id))

			commit('updateHasSelectedPostTargetsLinkedChat', boardTargets.every(t => t.linked_chat_id !== null))
		} else {
			commit('updateHasSelectedPostTargetsLinkedChat', false)
		}
	},
	clearBotStores: () => {
		store.commit('clear_active_board_config')
		store.commit('CLEAR_MENU_OPTIONS')

		store.commit('CLEAR_FLOWS_FROM_STORE')
		store.commit('CLEAR_FORMS_FROM_STORE')
		store.commit('CLEAR_ARTICLES_FROM_STORE')
		store.commit('CLEAR_DRAFTS_FROM_STORE')
		store.commit('reset_shop_state_fields')
		store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', null)
	},
	// posts planner
	...boardsActions,
	// active channel
	...activeChannel,
	// articles
	...articlesActions,
	// flows
	...flowsActions,
	...statisticsActions,
	...notificationActions,
	...propertiesActions,
	...labelsAction,
	...shopAction,
	...styleActions,
	...formsActions,
	...draftsActions,
	...menuOptionsActions,
	...inviteLinksAction,
};

export default actions;
