import BaseApi from '@/includes/services/BaseApi'
import {
    AnsweredUser,
    Form,
    FormAnswer,
    FormAnswerRequest,
    FormAnswersRequest,
    TestFormRequest
} from '@/includes/types/Form.types'

export class FormService {
    static async createForm(type: string, body: Form & { board_key:string }): Promise<{ form: Required<Form> }> {
        return BaseApi.sendRequest(type, 'createForm', body)
    }

    static async getForms(type: string, body: { board_key:string }): Promise<{ items: Array<Required<Form>> }> {
        return BaseApi.sendRequest(type, 'getForms', body)
    }

    static async getForm(type: string, body: { board_key:string, key: string }): Promise<{ form: Required<Form> }> {
        return BaseApi.sendRequest(type, 'getForm', body)
    }

    static async deleteForm(type: string, body: { board_key:string, key: string }): Promise<{ form: Required<Form> }> {
        return BaseApi.sendRequest(type, 'deleteForm', body)
    }

    static async createFormAnswer(type:string, body: FormAnswerRequest):Promise<{url: string}> {
        return BaseApi.sendRequest(type, 'createFormAnswer', body)
    }

    static async testForm(type:string, body: TestFormRequest): Promise<{result: string}> {
        return BaseApi.sendRequest(type, 'testForm', body)
    }

    static async updateForm(type:string, body: Form & { board_key:string }):Promise<{ form: Required<Form> }> {
        return BaseApi.sendRequest(type, 'updateForm', body)
    }

    static async getFormAnswers(type:string, body: FormAnswersRequest):Promise<{ items: Array<FormAnswer> }> {
        return BaseApi.sendRequest(type, 'getFormAnswers', body)
    }

    static async getQuestionnaire(type:string, body: { token: string }):Promise<{ form: Required<Form>, user: AnsweredUser, board_key: string }> {
        return BaseApi.sendRequest(type, 'getQuestionnaire', body)
    }
}