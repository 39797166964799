































import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { IPost, Poll as PollMessage, Quiz } from "@/includes/types/Post.types";
import i18n from "@/i18n/i18n";

@Component({
  data() {
    return {
      i18n
    }
  }
})
export default class PollQuiz extends Vue {
  @Prop() message!: PollMessage | Quiz
}
