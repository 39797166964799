import { RootState } from '@/store/state'
import { ListInviteLinkModel } from '@/components/InviteLinks/types'

export default {
	setInviteLinksToStore(state: RootState, links: Array<ListInviteLinkModel>) {
		state.inviteLinksState.links = links
	},
	deleterInviteLink(state: RootState, key: string) {
		if (state.inviteLinksState.links) {
			const index = state.inviteLinksState.links.findIndex((link) => link.key === key)

			if (index !== -1) {
				state.inviteLinksState.links.splice(index, 1)
			}
		}
	},
	addInviteLink(state: RootState, link: ListInviteLinkModel) {
		state.inviteLinksState.links!.push(link)
	},
	updateInviteLink(state: RootState, link: ListInviteLinkModel) {
		const index = state.inviteLinksState.links!.findIndex((l) => l.key === link.key)

		if (index !== -1) {
			state.inviteLinksState.links!.splice(index, 1, link)
		}
	},
	clear_invite_links(state: RootState) {
		state.inviteLinksState.links = null
	}
}
