








import i18n from '@/i18n/i18n'

import axios from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import ruRu from "ant-design-vue/lib/locale-provider/ru_RU";
import enUS from 'ant-design-vue/lib/locale-provider/en_US'

@Component
export default class App extends Vue {
  translationsLoaded = false

  get currentLocale() {
    if (this.$i18n.locale === 'ru') {
      return ruRu
    }

    if (this.$i18n.locale === 'en') {
      return enUS
    }

    return ruRu
  }

  mounted() {
    // Global site tag (gtag.js) - Google Statistic
    //   new ScriptLoader(['https://www.googletagmanager.com/gtag/js?id=UA-154663624-1'], () => {
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag() {window.dataLayer.push(arguments);}
    //     gtag("js", new Date());
    //     gtag("config", "UA-154663624-1");
    //   }).require()
    //

    axios.get(`/translations/common.json`).then(({ data }) => {

      i18n.mergeLocaleMessage('ru', data['ru'])
      i18n.mergeLocaleMessage('en', data['en'])

      this.$i18n.mergeLocaleMessage('ru', data['ru'])
      this.$i18n.mergeLocaleMessage('en', data['en'])

      this.translationsLoaded = true
    }).catch(console.error)
  }
}
