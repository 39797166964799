import { RootState } from '@/store/state'
import { Slot, Target } from '@/includes/types/Board.types'

export default {
    set_active_channel_to_store(state: RootState, payload: Target): void {
        state.channelsState.activeChannel = payload
    },
    reset_active_channel_from_store(state: RootState): void {
        state.channelsState.activeChannel = null
    },
    add_slot_to_channel(state: RootState, payload: Slot): void {
        state.channelsState.activeChannel?.config.slots.push(payload)
    },
    reset_slots(state: RootState): void {
        state.channelsState.activeChannel!.config.slots = []
    },
    set_slots_to_config(state: RootState, payload: Array<Slot>):void {
        state.channelsState.activeChannel!.config.slots = payload
    },
    set_copied_slot_to_store(state: RootState, payload: Array<Slot>):void {
        state.channelsState.copiedSlots = payload
    },
    reset_copied_slots_from_store(state: RootState): void {
        state.channelsState.copiedSlots = null
    },
    remove_slot(state: RootState, index: number): void {
        state.channelsState.activeChannel?.config.slots.splice(index, 1)
    },
    update_slot(state: RootState, payload: { itemIndex: number, slot: Slot & { index?: number } }): void {
        delete payload.slot.index
        state.channelsState.activeChannel?.config.slots.splice(payload.itemIndex, 1, payload.slot)
    }
}