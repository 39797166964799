import BaseApi from '@/includes/services/BaseApi'
import {
	Product,
	ProductState,
	EditProductModel,
	CreateProductModel,
} from 'piramis-base-components/src/components/Shop/Products/types'
import {
	CashRegisterModel,
	CashRegistersState,
	CreateCashRegisterModel,
	UpdateCashRegisterModel,
} from 'piramis-base-components/src/components/Shop/CashRegisters/types'
import { Notice } from 'piramis-base-components/src/components/Shop/Notices/types'
import { Report } from 'piramis-base-components/src/components/Shop/ProductReports/types'

export class ShopService {
	static createProduct(type: string, body: { board_key: string } & CreateProductModel): Promise<{ data: Product }> {
		return BaseApi.sendRequest(type, 'CreateProduct', body)
	}

	static deleteProduct(type: string, body: { board_key: string, id: number }): Promise<{ state: ProductState.Hidden | ProductState.Removed }> {
		return BaseApi.sendRequest(type, 'DeleteProduct', body)
	}

	static editProduct(type: string, body: { board_key: string } & EditProductModel): Promise<{ data: Product }> {
		return BaseApi.sendRequest(type, 'EditProduct', body)
	}

	static getProduct(type: string, body: { board_key: string, id: number }): Promise<Product> {
		return BaseApi.sendRequest(type, 'GetProduct', body)
	}

	static getProducts(type: string, body: { board_key: string }): Promise<{ items: Array<Product> }> {
		return BaseApi.sendRequest(type, 'GetProducts', body)
	}

	static getCashRegistries(type: string, body: { board_key: string }): Promise<{ items: Array<CashRegisterModel> }> {
		return BaseApi.sendRequest(type, 'GetCashRegistries', body)
	}

	static createCashRegister(type: string, body: { board_key: string} & CreateCashRegisterModel): Promise<{ data: CashRegisterModel }> {
		return BaseApi.sendRequest(type, 'CreateCashRegister', body)
	}

	static editCashRegister(type: string, body: { board_key: string } & UpdateCashRegisterModel): Promise<{ data: CashRegisterModel }> {
		return BaseApi.sendRequest(type, 'EditCashRegister', body)
	}

	static deleteCashRegister(type: string, body: { board_key: string, id: number }): Promise<{ state: CashRegistersState }> {
		return BaseApi.sendRequest(type, 'DeleteCashRegister', body)
	}

	static getNotices(type: string, body: { board_key: string, offset: number, limit: number, customer_id?: number }): Promise<{ items: Array<Notice> }> {
		return BaseApi.sendRequest(type, 'GetNotices', body)
	}

	static getProductsReport(type: string, body: { board_key: string, customer_id?: number, product_id?: number }): Promise<{ items: Array<Report> }> {
		return BaseApi.sendRequest(type, 'GetProductsReport', body)
	}

	static restoreProduct(type: string, body: { board_key: string, id: number }): Promise<void> {
		return BaseApi.sendRequest(type, 'RestoreProduct', body)
	}
}

// export type Report = {
// 	product: Product
// 	sold: number
// 	suggested: number
// 	sum: number
// }
//
// export type Notice = {
// 	id: number
// 	cashRegister: CashRegister
// 	customer: Customer
// 	date: string
// 	/* Сумма всего зааказа без скидки */
// 	sum: number
// 	/* Процент скидки на весь заказ */
// 	discount?: number
// 	/* Сумма скидки на весь заказ */
// 	discount_sum?: number
// 	/* Стоимость всего заказа с учетом скидки */
// 	total: number
// 	details: Array<{
// 		product: Product
// 		/* Стоимость 1 позиции */
// 		cost: number
// 		/* Процент скидки на 1 позицию */
// 		discount?: number
// 		/* Сумма скидки на одну позицию */
// 		discount_sum?: number
// 		count: number
// 		/* Стоимость позиций без учета скидки в зависимости от кол-ва позиции */
// 		sum: number
// 		/* Стоимость позиций с учетом скидки в зависимости от кол-ва позиции */
// 		total: number
// 	}>
// 	payment?: {
// 		date: string
// 		id: string
// 	}
// }
//
// export type Customer = {
// 	id: number
// 	login: string
// 	name: string
// 	language: string
// }
//
// export type CashRegister = {
// 	name: string
// 	id: number
// 	token: string
// 	currency: string
// 	type: string
// }

// export type Product = {
// 	cost: number
// 	name: string
// 	description: string
// 	id: number
// 	image: string | null
// 	type: ProductType
// 	currency: string
// }

// type t = {
// 	cost: number,
// 	name:	string,
// 	description: string,
// 	id: number,
// 	image: string,
// 	currency: string
// }
